import { myFirebase, db, firebase, storage } from "../firebase/firebase";

const takeLastSplitHandler = (string) => {
    return string.split("/").pop()
}

const numberWithCommasHandler = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const getStats = async () => {
    return db.collection("system").doc("--stats--").get()
        .then(querySnapshot => {
            return querySnapshot.data()
        }).catch(function (error) {
            console.error("Error returning user's roles: ", error);
        });
}

const getRoles = async () => {
    return db.collection("system").doc("--roles--").get()
        .then(querySnapshot => {
            return querySnapshot.data()
        }).catch(function (error) {
            console.error("Error returning user's roles: ", error);
        });
}

const getRole = async () => {
    const userId = myFirebase.auth().currentUser.uid;
    return db.collection("users").doc(userId).get()
        .then(querySnapshot => {
            const role = querySnapshot.data().role;
            return role ? role : 1000;
        }).catch(function (error) {
            console.error("Error returning user's role: ", error);
            return 1000;
        });
}

const getEmail = async (userId) => {
    return db.collection("users").doc(userId).get()
        .then(querySnapshot => {
            const userEmail = querySnapshot.data().communicationEmail ? querySnapshot.data().communicationEmail : querySnapshot.data().email;
            return userEmail ? userEmail : "";
        }).catch(function (error) {
            console.error("Error returning user's email: ", error);
            return "";
        });
}

const getTypes = async () => {
    const userId = myFirebase.auth().currentUser.uid;
    let data = [];
    db.collection("bottle_type").get()
        .then(querySnapshot => {
            querySnapshot.forEach(function (documentSnapshot) {
                if (documentSnapshot.data().userId === userId || documentSnapshot.data().userId === undefined
                    || documentSnapshot.data().userId === null || documentSnapshot.data().status === "public" ||
                    (documentSnapshot.data().createdBy != null && documentSnapshot.data().createdBy.includes(userId))) {
                    let ingredientType = documentSnapshot.id;
                    data.push({ value: ingredientType, label: ingredientType, owner: (documentSnapshot.data().userId === userId || (documentSnapshot.data().createdBy != null && documentSnapshot.data().createdBy.includes(userId) && documentSnapshot.data().status === "public")) })
                }
            })
        }).catch(function (error) {
            console.error("Error returning user's role: ", error);
        });
    data.push({ value: "newType", label: "--  New type --" })
    return data;
}

const getGlass = async () => {
    let data = [{ value: "", label: "Leave empty" }];
    db.collection("glass_type").get()
        .then(querySnapshot => {
            querySnapshot.forEach(function (documentSnapshot) {
                let glassType = documentSnapshot.id;
                data.push({ value: glassType, label: glassType })
            })
        }).catch(function (error) {
            console.error("Error returning user's role: ", error);
        });
    return data;
}

const deleteImageHandler = async (imageAsFile) => {
    if (imageAsFile === '') {
        console.log(`Not an image`)
    } else {
        let desertRef = storage.refFromURL(imageAsFile);
        desertRef.delete().then(function () {
            console.log("Image deleted successfully");
        }).catch(function (error) {
            console.log("Error deleting image: ", error.message);
        });
    }
}


function addNewTypeHandler(type, forcePublish) {
    const userId = myFirebase.auth().currentUser.uid;
    let promise = db.collection('bottle_type')
        .doc(type)
        .get().then((doc) => {
            if (!doc.exists) {
                return db.collection("bottle_type").doc(type)
                    .set(
                        {
                            userId: userId,
                            status: "private",
                            createdBy: [userId],
                        }
                    ).then(function () {
                        console.log("Bottle type successfully added!");
                        return true;
                    }).catch(function (error) {
                        console.log("Error adding Favorite: ", error.message);
                        return false;
                    });
            } else if (doc.data().userId && (doc.data().userId !== userId || forcePublish)) {
                let batch = db.batch()
                let typeRef = db.collection("bottle_type").doc(type)
                // TODO: Verify is admin by checking role!!!
                if (forcePublish) {
                    batch.set(typeRef, { userId: firebase.firestore.FieldValue.delete(), status: "public", createdBy: firebase.firestore.FieldValue.arrayUnion(userId) }, { merge: true })
                } else {
                    batch.set(typeRef, { createdBy: firebase.firestore.FieldValue.arrayUnion(userId) }, { merge: true })
                }
                return batch.commit().then(function () {
                    console.log("Bottle type successfully updated!");
                    return true;
                }).catch(function (error) {
                    console.log("Error removing userId for bottle type: ", error.message);
                    return false;
                });
            }
        });
    return promise;
}

function textTruncateHandler(str, length, ending) {
    length = length ? length : 110
    ending = ending ? ending : '...'
    if (str && str.length > length) {
        return str.substr(0, str.substring(0, length - ending.length).lastIndexOf(" ")) + ending;
    } else {
        return str;
    }
};

function titleCaseHandler(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

const handleFireBaseUploadHandler = async (imageAsFile, itemId, folder, props) => {
    if (imageAsFile === '') {
        console.log(`not an image, the image file is a ${typeof (imageAsFile)}`)
    } else {
        const uploadTask = storage.ref(`/${folder}/${itemId}`).put(imageAsFile)
        //initiates the firebase side uploading
        uploadTask.on('state_changed',
            (snapShot) => {
                //takes a snap shot of the process as it is happening
                console.log(snapShot)
            }, (err) => {
                //catches the errors
                console.log(err)
            }, () => {
                // gets the functions from storage refences the image storage in firebase by the children
                // gets the download url then sets the image from firebase as the value for the imgUrl key:
                storage.ref(folder).child(`${itemId}`).getDownloadURL()
                    .then(fireBaseUrl => {
                        setImageAsUrl(fireBaseUrl, itemId, folder, props)
                    })
            })
    }
}

function setImageAsUrl(image, itemId, folder, props) {
    return db.collection(`${folder}`).doc(itemId).update({
        image: image
    }).then(function () {
        console.log("Image added to the cocktail!");
        props.history.push(`/admin/${folder}/`);
        return true;
    }).catch(function (error) {
        console.log("Error adding image: ", error.message);
        return false;
    });
}

export const getUserRoles = getRoles;
export const getSystemStats = getStats;
export const getUserRole = getRole;
export const getUserEmail = getEmail;
export const getIngredientTypes = getTypes;
export const getGlassTypes = getGlass;
export const deleteImage = deleteImageHandler;
export const addNewType = addNewTypeHandler;
export const titleCase = titleCaseHandler;
export const textTruncate = textTruncateHandler;
export const handleFireBaseUpload = handleFireBaseUploadHandler;
export const numberWithCommas = numberWithCommasHandler;
export const takeLastSplit = takeLastSplitHandler;
export const mailToAdmins = ['info@mybar.io']
