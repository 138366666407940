import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.3.0";
import "assets/css/demo.css";

import ProtectedRoute from "./components/ProtectedRoute";
import Admin from "./layouts/Admin.jsx";
import Login from "./layouts/Login";

function App(props) {
  return (
    <Switch>
      <ProtectedRoute path="/admin" component={Admin} {...props} />
      <Route path="/login" component={Login} />
      <Redirect to="/admin/dashboard" />
    </Switch>
  );
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying
    };
}

export default compose(
    connect(mapStateToProps),
)(App);
