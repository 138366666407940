import * as firebaseApp from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyC_eUSXnhuuYqPizFy6hcbEyP76TeMy5uY",
    // bartender-72061.firebaseapp.com - auth.mybar.io
    authDomain: "auth.mybar.io",
    databaseURL: "https://bartender-72061.firebaseio.com",
    projectId: "bartender-72061",
    storageBucket: "bartender-72061.appspot.com",
    messagingSenderId: "375586004977"
};

const firebase = firebaseApp;
const myFirebase = firebaseApp.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const storage = firebaseApp.storage();

export  {
   myFirebase, db, storage, firebase
 }
