import React from "react";
import Select from "react-select";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Form,
  Input,
  FormGroup
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import { getUser, updateUser, userDefaultImage } from "../../firebase/users";
import countries from "assets/json/countries.json";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.location.pathname.split("/")[3] ? props.location.pathname.split("/")[3] : "",
      currentImage: userDefaultImage,
      image: "",
      email: "",
      name: "",
      address: "",
      country: "",
      city: "",
      state: "",
      zipcode: "",
      aboutMe: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      requiredEmail: "",
      requiredName: "",
      hasFlag: [ "AD", "BE", "CG", "DE", "FI", "HT", "IR", "KP", "LS", "MT", "NO", "PT", "SE", "TJ", "VN", "AE", "BF", "CH",
      "DJ", "FR", "HU", "IT", "KR", "LU", "MX", "OM", "PY", "SG", "TL", "YE", "AG", "BG", "CL", "DK", "GA", "ID", "JM", "KW",
      "LV", "NA", "PA", "QA", "SL", "TR", "AM", "BO", "CM", "DZ", "GB", "IE", "JO", "KZ", "MG", "NE", "PE", "RO", "SN", "TZ",
      "AR", "BR", "CN", "EE", "GM", "IL", "JP", "LA", "MK", "NG", "PG", "RU", "SO", "UA", "AT", "CA", "CO", "EG", "GT", "IN",
      "KG", "LB", "ML", "NI", "PK", "RW", "SV", "US", "AU", "CD", "CZ", "ES", "HN", "IQ", "KN", "LC", "MM", "NL", "PL", "SA",
      "TD", "VE"]
    };
  }

  componentDidMount() {
    getUser(this.state.userId).then((user) => { this.setState(user) });
    if (countries) {
        let contriesOptions = [{value: "", label: "-- Leave empty --"}];
        Object.keys(countries).forEach(function(k, v){
            contriesOptions.push({value: k, label: countries[k]})
        });
        contriesOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.setState({contriesOptions})
    }
    this._isMounted = true;
  }

  componentDidUpdate() {
    //console.log(this.state)
  }

  storeImage = (image) =>  {
      this.setState({ image });
  }

  typeRequired = type => {
    if (type && type.length > 0) {
      return "has-success";
    } else {
      return "has-danger";
    }
  }

  typeRequiredName = e => {
    e.preventDefault()
    const name = e.target.value
    this.setState({ name })
    this.setState({ requiredName: this.typeRequired(this.state.name) })
  }

  typeRequiredEmail = e => {
    if (e && e.target) {
        this.setState({ email: e.target.value })
    }
    const email = this.state.email;
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
    let requiredEmail = ""
    if (emailRex.test(email)) {
      requiredEmail = "has-success";
    } else {
      requiredEmail = "has-danger";
    }
    this.setState({ requiredEmail });
    return requiredEmail
  }

  typeValidate = e =>  {
    e.preventDefault()
    let success = true;
    const requiredName = this.typeRequired(this.state.name)
    this.setState({ requiredName });
    if (requiredName !== "has-success") {
      success = false;
    }
    const requiredEmail = this.typeRequiredEmail(this.state.email)
    this.setState({ requiredEmail });
    if (requiredName !== "has-success") {
      success = false;
    }
    if (success) {
        updateUser(this.state.userId, this.state.name, this.state.email,
        this.state.address, this.state.country, this.state.city, this.state.state, this.state.zipcode,
        this.state.aboutMe, this.state.twitter, this.state.instagram, this.state.linkedin, this.state.image, this.props)
        .then((response) => {
            if (response) {
                let message = "User updated"
                let type = "success"
                this.props.showNotification(message, type);
            } else {
                let message = "Error updating user"
                let type = "danger"
                this.props.showNotification(message, type);
            }
        })
    } else {
        let message = "Please fill all required fields"
        let type = "danger"
        this.props.showNotification(message, type);
    }
  }

  getSocialLinks = () => {
      if (this.state.twitter || this.state.instagram || this.state.linkedin) {
        return (
            <div className="button-container">
                <hr />
                {this.state.twitter &&
                    <Button
                      className="btn-icon btn-round"
                      color="neutral"
                      href="#twitter"
                      onClick={e => { e.preventDefault(); window.open(`https://twitter.com/${this.state.twitter}`, '_blank');}}
                      size="lg" >
                      <i className="fab fa-twitter" />
                    </Button>
                }{this.state.instagram &&
                    <Button
                      className="btn-icon btn-round"
                      color="neutral"
                      href="#instagram"
                      onClick={e => { e.preventDefault(); window.open(`https://instagram.com/${this.state.instagram}`, '_blank');}}
                      size="lg" >
                      <i className="fab fa-instagram" />
                    </Button>
                }{this.state.linkedin &&
                    <Button
                      className="btn-icon btn-round"
                      color="neutral"
                      href="#linkedIn"
                      onClick={e => { e.preventDefault(); window.open(`https://www.linkedin.com/in/${this.state.linkedin}`, '_blank');}}
                      size="lg" >
                      <i className="fab fa-linkedin" />
                    </Button>
                }
         </div>
        )
      }
  }

  render() {
    if (this.state.email) {
        return (
          <>
            <PanelHeader size="sm" />
            <div className="content">
              <Row>
                <Col md="8">
                  <Card>
                    <CardHeader>
                      <h5 className="title">Edit Profile</h5>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col className="pr-1" md="6">
                            <FormGroup className={this.state.requiredEmail}>
                              <label>Communications Email</label>
                              <Input
                                value={this.state.email}
                                placeholder="Email"
                                onChange={e => this.typeRequiredEmail(e)}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pl-1" md="6">
                            <FormGroup className={this.state.requiredName}>
                              <label>Display Name</label>
                              <Input
                                placeholder="Display Name"
                                onChange={e => this.typeRequiredName(e)}
                                value={this.state.name}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="8">
                            <FormGroup>
                              <label>Address</label>
                              <Input
                                placeholder="Address"
                                onChange={event => this.setState({"address": event.target.value })}
                                value={this.state.address}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pl-1" md="4">
                            <FormGroup>
                              <label>Country</label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Select Country"
                                name="singleSelect"
                                value={this.state.contriesOptions && this.state.country ?
                                this.state.contriesOptions.filter(option => option.label === this.state.country || option.value === this.state.country) : ""}
                                options={this.state.contriesOptions}
                                onChange={e => { this.setState({"country": e.value }) }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="4">
                            <FormGroup>
                              <label>City</label>
                              <Input
                                placeholder="City"
                                onChange={event => this.setState({"city": event.target.value })}
                                value={this.state.city}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col className="px-1" md="4">
                            <FormGroup>
                              <label>State/Province</label>
                              <Input
                                placeholder="State/Province"
                                onChange={event => this.setState({"state": event.target.value })}
                                value={this.state.state}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pl-1" md="4">
                            <FormGroup>
                              <label>Postal Code</label>
                              <Input
                                placeholder="ZIP Code"
                                type="number"
                                onChange={event => this.setState({"zipcode": event.target.value })}
                                value={this.state.zipcode}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label>About Me</label>
                              <Input
                                cols="80"
                                onChange={event => this.setState({"aboutMe": event.target.value })}
                                value={this.state.aboutMe}
                                placeholder="Here can go samething about you..."
                                rows="6"
                                type="textarea"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pl-1" xs={3} sm="3" md="4" lg="4" style={{paddingTop: "20px"}}>
                            <FormGroup>
                              <PictureUpload defaultValue="" storeImage={(e) => this.storeImage(e)} />
                            </FormGroup>
                          </Col>
                          <Col md="8">
                             <Row>
                                  <Col md="12">
                                      <FormGroup>
                                        <label>Twitter (Username only)</label>
                                        <Input
                                          onChange={event => this.setState({"twitter": event.target.value })}
                                          value={this.state.twitter}
                                          placeholder="Twitter Username"
                                          type="text"
                                        />
                                      </FormGroup>
                                  </Col>
                              </Row>
                              <Row>
                                   <Col md="12">
                                       <FormGroup>
                                         <label>Instagram (Username only)</label>
                                         <Input
                                           onChange={event => this.setState({"instagram": event.target.value })}
                                           value={this.state.instagram}
                                           placeholder="Instagram Username"
                                           type="text"
                                         />
                                       </FormGroup>
                                   </Col>
                               </Row>
                               <Row>
                                    <Col md="12">
                                        <FormGroup>
                                          <label>LinkedIn (Username only)</label>
                                          <Input
                                            onChange={event => this.setState({"linkedin": event.target.value })}
                                            value={this.state.linkedin}
                                            placeholder="LinkedIn Username"
                                            type="text"
                                          />
                                        </FormGroup>
                                    </Col>
                                </Row>
                          </Col>
                          </Row>
                      </Form>
                    </CardBody>
                    <CardFooter className="text-left">
                      <Button color="primary" onClick={e => this.typeValidate(e)}>Save</Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-user">
                    <div className="image">
                      <img alt="..." src={require("assets/img/bg5.jpg")} />
                    </div>
                    <CardBody>
                      <div className="author">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar border-gray"
                            src={this.state.currentImage}
                          />
                          <h5 className="title">{this.state.name}
                            { this.state.country && this.state.hasFlag.includes(this.state.country) &&
                            <img src={ require(`../../assets/img/flags/${this.state.country}.png`)}
                            style={{paddingLeft: "10px", paddingBottom: "3px"}} alt={`${this.state.country}_flag`} />}
                          </h5>
                        </a>
                      </div>
                      <p className="description text-center">
                        {this.state.aboutMe}
                      </p>
                    </CardBody>
                    {this.getSocialLinks()}
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        );
    } else {
         return (
           <>
             <PanelHeader size="sm" />
             <div className="content">
               <Row>
                 <Col md="4">
                   <Card className="card-user">
                     <div className="image">
                       <img alt="..." src={require("assets/img/bg5.jpg")} />
                     </div>
                     <CardBody>
                       <div className="author">
                         <a href="#pablo" onClick={e => e.preventDefault()}>
                           <img
                             alt="..."
                             className="avatar border-gray"
                             src={this.state.currentImage}
                           />
                           <h5 className="title">Mr. Not Here</h5>
                         </a>
                       </div>
                       <p className="description text-center">
                          The user you are looking for doesn't exist. It vanished like a ghost...
                       </p>
                     </CardBody>
                   </Card>
                 </Col>
               </Row>
             </div>
           </>
         );

    }
  }
}

export default User;
