import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { myFirebase } from "../firebase/firebase";
import { fetchUserMenu } from "../firebase/routes";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications
import NotificationAlert from "react-notification-alert";

// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";

var ps;

class Admin extends React.Component {
  state = {
    sidebarMini: false,
    backgroundColor: "orange",
    userId: myFirebase.auth().currentUser.uid,
    routes: []
  };

  notificationAlert = React.createRef();
  mainPanel = React.createRef();

  componentDidMount = async () => {
    this.setState(await fetchUserMenu())
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.mainPanel.current);
    }
  }

  componentWillUnmount = () => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      ps = null;
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate = (e) => {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainPanel.current.scrollTop = 0;
    }
  }

  startExpanded = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  minimizeSidebar = () => {
    var message = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      message += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      message += "activated...";
    }
    document.body.classList.toggle("sidebar-mini");
    let type = "info"
    this.showNotification(message, type)
  }

  showNotification = (message, type) => {
    message = message ? message : "Hello";
    type = type ? type : "info";
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    this.notificationAlert.current.notificationAlert(options);
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        let PropsPage = prop.component
        return (
          <Route
            path={prop.layout + prop.path}
            component={(proplist) => <PropsPage {...proplist}
            showNotification={this.showNotification }/> }
            key={key}
          />
        );
      } else if (prop.layout === "redirect") {
          return (
              <Redirect from="/admin" to="/admin/dashboard" key="redirect"/>
          )
      } else {
        return null;
      }
    });
  };

  getActiveRoute = routes => {
    let activeRoute = "Loading...";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  render(){
    return(
      <div className="wrapper">
        <NotificationAlert ref={this.notificationAlert} />
        <Sidebar
          {...this.props}
          routes={this.state.routes}
          userId={this.state.userId}
          userName={this.state.userName}
          userPhoto={this.state.userPhoto}
          sidebarMini={this.state.sidebarMini}
          startExpanded={this.startExpanded}
          minimizeSidebar={this.minimizeSidebar}
          backgroundColor={this.state.backgroundColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <AdminNavbar
            {...this.props}
            brandText={this.getActiveRoute(this.state.routes)}
          />
          <Switch>
            {this.getRoutes(this.state.routes)}
          </Switch>
          {// we don't want the Footer to be rendered on full screen maps page
          window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
            <Footer fluid />
          )}
        </div>
      </div>
    );
  }
}

export default Admin;
