import { myFirebase, db, storage, firebase } from "../firebase/firebase";
import { getUserRole, getUserRoles, takeLastSplit} from "../firebase/utils";
import defaultImage from "../assets/img/default-avatar.png";
const folder = "users"

const handleFireBaseUpload = async (imageAsFile, userId, props) => {
    if(imageAsFile === '') {
        console.log(`not an image, the image file is a ${typeof(imageAsFile)}`)
    } else {
        const uploadTask = storage.ref(`/${folder}/${userId}`).put(imageAsFile)
        //initiates the firebase side uploading
        uploadTask.on('state_changed',
        (snapShot) => {
            //takes a snap shot of the process as it is happening
            console.log(snapShot)
        }, (err) => {
            //catches the errors
            console.log(err)
        }, () => {
            // gets the functions from storage refences the image storage in firebase by the children
            // gets the download url then sets the image from firebase as the value for the imgUrl key:
            storage.ref(folder).child(`${userId}`).getDownloadURL()
             .then(fireBaseUrl => {
               setImageAsUrl(fireBaseUrl, userId, props)
             })
        })
    }
}

const deleteImage = async (imageAsFile) => {
    if(imageAsFile === '') {
        console.log(`Not an image`)
    } else {
        let desertRef = storage.refFromURL(imageAsFile);
        desertRef.delete().then(function() {
            console.log("Image deleted successfully");
        }).catch(function(error) {
            console.log("Error deleting image: ", error);
        });
    }
}

function setImageAsUrl(image, userId, props) {
    let promise = db.collection("users").doc(userId).update({
      image: image
    }).then(function() {
        console.log("Image added to the user!");
        userId = userId === myFirebase.auth().currentUser.uid ? "" : userId
        if (!userId) {
            window.location.reload(false);
        } else {
            props.history.push(`/admin/profile/${userId}`);
        }
        return true;
    }).catch(function(error) {
        console.log("Error adding image: ", error);
        return false;
    });
    return promise;
}

const getUsersHandler = async () => {
    return getUserRole().then((role) => {
        return db.collection('users')
            .orderBy('displayName', 'asc')
            .get()
            .then(async (data) => {
                let users = [];
                const roles = await getUserRoles()
                data.forEach((user) => {
                    let currentImage;
                    if (user.data().image) {
                        currentImage = user.data().image
                    } else {
                        currentImage = user.data().photoURL ? user.data().photoURL : defaultImage;
                    }
                    users.push({
                        id: user.id,
                        currentImage,
                        image: user.data().image ? user.data().image : user.data().photoURL,
                        email: user.data().communicationEmail ? user.data().communicationEmail : user.data().email,
                        name: user.data().name ? user.data().name : user.data().displayName,
                        role: user.data().role ? roles[user.data().role] : "User",
                        lastLogin: user.data().lastLogin ?  user.data().lastLogin.seconds.toString() : "",
                    });
                })
                return { users };
            })
    })
}

function getUserHandler(userIdValue) {
    const userId = myFirebase.auth().currentUser.uid;
    let promise;
    if (userIdValue && userId !== userIdValue) {
        promise = getUserRole().then((role) => {
            if (role < 5) {
                return db.collection('users').doc(userIdValue)
                    .get().then((user) => {
                        if (!user.empty && user.data()) {
                            let currentImage;
                            if (user.data().image) {
                                currentImage = user.data().image
                            } else {
                                currentImage = user.data().photoURL ? user.data().photoURL : defaultImage;
                            }
                            return {
                                currentImage,
                                image: user.data().image ? user.data().image : user.data().photoURL,
                                email: user.data().communicationEmail ? user.data().communicationEmail : user.data().email,
                                name: user.data().name ? user.data().name : user.data().displayName,
                                address: user.data().address ? user.data().address : "",
                                city: user.data().city ? user.data().city : "",
                                state: user.data().state ? user.data().state : "",
                                country: user.data().country ? user.data().country : "",
                                zipcode: user.data().zipcode ? user.data().zipcode : "",
                                aboutMe: user.data().aboutMe ? user.data().aboutMe : "",
                                twitter: user.data().twitter ? user.data().twitter : "",
                                instagram: user.data().instagram ? user.data().instagram : "",
                                linkedin: user.data().linkedin ? user.data().linkedin : ""
                            };
                        } else {
                            console.log("The user doesn't exists.");
                            return {};
                        }
                    });
            }
        });
    } else {
        promise = db.collection('users').doc(userId)
            .get().then((user) => {
                if (!user.empty && user.data()) {
                    let currentImage;
                    if (user.data().image) {
                        currentImage = user.data().image
                    } else {
                        currentImage = user.data().photoURL ?user.data().photoURL : defaultImage;
                    }
                    return {
                        currentImage,
                        image: user.data().image ? user.data().image : user.data().photoURL,
                        email: user.data().communicationEmail ? user.data().communicationEmail : user.data().email,
                        name: user.data().name ? user.data().name : user.data().displayName,
                        address: user.data().address ? user.data().address : "",
                        city: user.data().city ? user.data().city : "",
                        state: user.data().state ? user.data().state : "",
                        country: user.data().country ? user.data().country : "",
                        zipcode: user.data().zipcode ? user.data().zipcode : "",
                        aboutMe: user.data().aboutMe ? user.data().aboutMe : "",
                        twitter: user.data().twitter ? user.data().twitter : "",
                        instagram: user.data().instagram ? user.data().instagram : "",
                        linkedin: user.data().linkedin ? user.data().linkedin : ""
                    };
                } else {
                    console.log("The user doesn't exists.");
                    return false;
                }
            });
    }
    return promise;
}

const updateLoggedUserHandler  = async (displayName, email, emailVerified, photoURL) => {
    let userId = myFirebase.auth().currentUser.uid;
    let data = {}
    if (displayName) { data["displayName"] = displayName }
    if (email) { data["email"] = email }
    if (emailVerified) { data["emailVerified"] = emailVerified }
    if (photoURL) { data["photoURL"] = photoURL }
    data["lastLogin"] = firebase.firestore.Timestamp.fromDate(new Date());
    let usersRef = db.collection('users');
    return await usersRef.doc(userId).set(data, {merge: true}).then(function(docRef) {
        return "true";
    }).catch(function(error) {
        return false
    });
}

const updateUserHandler  = async (userId, name, email, address, country, city, state, zipcode, aboutMe,
    twitter, instagram, linkedin, image, props) => {
    let data = {}
    data["name"] = name ? name : firebase.firestore.FieldValue.delete()
    data["email"] = email ? email : firebase.firestore.FieldValue.delete()
    data["address"] = address ? address : firebase.firestore.FieldValue.delete()
    data["country"] = country ? country : firebase.firestore.FieldValue.delete()
    data["city"] = city ? city : firebase.firestore.FieldValue.delete()
    data["state"] = state ? state : firebase.firestore.FieldValue.delete()
    data["zipcode"] = zipcode ? zipcode : firebase.firestore.FieldValue.delete()
    data["aboutMe"] = aboutMe ? aboutMe : firebase.firestore.FieldValue.delete()
    data["twitter"] = twitter ? takeLastSplit(twitter) : firebase.firestore.FieldValue.delete()
    data["instagram"] = instagram ? takeLastSplit(instagram) : firebase.firestore.FieldValue.delete()
    data["linkedin"] = linkedin ? takeLastSplit(linkedin) : firebase.firestore.FieldValue.delete()
    if (userId !== undefined && userId !== "" && userId !== null) {
        return getUserRole().then(async (role) => {
            if (role <= 5) {
                let usersRef = db.collection('users');
                let promise = await usersRef.doc(userId).update(data).then(function(docRef) {
                    console.log("User successfully added!");
                    if (image && image.type) {
                        handleFireBaseUpload(image, docRef ? docRef.id : userId, props); return true
                    } else {
                        if (userId === myFirebase.auth().currentUser.uid) {
                            window.location.reload(false);
                        }
                        return "true";
                    }
                }).catch(function(error) {
                    console.log("Error adding user: ", error.message)
                    return false
                });
                return promise
            } else {
                console.log("Error adding or editing user.")
                return false
            }
        });
    } else {
        userId = myFirebase.auth().currentUser.uid;
        let usersRef = db.collection('users');
        let promise = await usersRef.doc(userId).update(data).then(function(docRef) {
            console.log("User successfully added!");
            if (image && image.type) { handleFireBaseUpload(image, docRef ? docRef.id : userId, props); return true}
            else {
                if (userId === myFirebase.auth().currentUser.uid) {
                    window.location.reload(false);
                }
                return "true";
            }
        }).catch(function(error) {
            console.log("Error adding user: ", error.message)
            return false
        });
        return promise
    }
}

const setUserRoleHandler  = async (userId, role) => {
    let data = {}
    data["role"] = role ? role : firebase.firestore.FieldValue.delete()
    if (userId !== undefined && userId !== "" && userId !== null) {
        return getUserRole().then(async (role) => {
            if (role <= 1) {
                let usersRef = db.collection('users');
                return await usersRef.doc(userId).update(data).then(function(docRef) {
                    console.log("User's role successfully updated!");
                    return true
                }).catch(function(error) {
                    console.log("Error updating user's role: ", error.message)
                    return false
                });
            } else {
                console.log("Error updating user's role.")
                return false
            }
        });
    } else {
        console.log("Error updating user's role.")
        return false
    }
}

function deleteUserHandler(userIdValue, image) {
    const userId = myFirebase.auth().currentUser.uid;
    return getUserRole().then((role) => {
        return db.collection('users').doc(userId).get()
            .then((data) => {
                // Check if I'm the owner of the user or role is < 10 which is editor or Admin
                if (role <= 10 || data.data().userId === userId) {
                    return db.collection("users").doc(userId).delete().then(function() {
                        console.log("User successfully deleted!");
                        if (image && image !== defaultImage) { deleteImage(image) }
                        return true;
                    }).catch(function(error) {
                        console.log("Error removing user: ", error.message);
                        return false;
                    });
                } else {
                    console.log("You don't have permission to delete this user.");
                    return false;
                }
            });
    });
}

//export const getUsers = getUsersHandler;
export const getUser = getUserHandler;
export const getUsers = getUsersHandler;
export const updateUser = updateUserHandler;
export const setUserRole = setUserRoleHandler;
export const updateLoggedUser= updateLoggedUserHandler;
export const deleteUser = deleteUserHandler;
export const userDefaultImage = defaultImage;
