/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")  + (this.props.login ? " login" : "")}
      >
        <Container fluid={this.props.fluid}>
          <div className="copyright">
            Copyright &copy; {1900 + new Date().getYear()} • MyBar
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.defaultProps = {
  login: false,
  default: false,
  fluid: false
};

Footer.propTypes = {
  login: PropTypes.bool,
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
