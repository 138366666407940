import React from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { getIngredientTypes, getGlassTypes } from "../../firebase/utils";
import { addCocktail, getCocktails, deletePublish, publishCocktail, rejectCocktail, unpublishCocktail } from "../../firebase/cocktails";

// reactstrap components
import {
  Alert,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

class CocktailForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unpublishNote: "",
      rejectionNote: "",
      owner: false,
      showWarning: false,
      showNote: false,
      selectOptionsType: [],
      selectOptionsUnit: [{ label: "oz", value: "oz" }, { label: "ml", value: "ml" }, { label: "L", value: "L" }],
      cocktailId: props.location.pathname.split("/")[4],
      name: { name: "", requiredNameState: "" },
      ingredients: [{ id: 0, type: "", quantity: "", unit: "oz" }],
      ingredientsList: [],
      alert: null,
      show: false,
    };
  }

  onDismiss = () => { this.setState({ showNote: false }) };

  componentDidMount = async () => {
    let ingredientTypes = await getIngredientTypes()
    let glassTypes = await getGlassTypes()
    if (this.state.cocktailId) {
      getCocktails(this.state.cocktailId).then(async (cocktail) => {
        if (cocktail) {
          let showWarning = false
          let newIngredients = []
          let newTypes = []
          await cocktail.ingredients.map(ingredient => {
            if (ingredientTypes.findIndex((ingred) => ingredient.type === ingred.value && !ingred.owner) > -1) {
              newIngredients.push(ingredient)
            } else {
              showWarning = true
              newIngredients.push({ id: ingredient.id, type: "newType", quantity: ingredient.quantity, unit: ingredient.unit, newType: ingredient.type })
              newTypes.push({ type: ingredient.type, forcePublish: true })
            }
            return true
          })
          cocktail.ingredients = newIngredients
          this.setState({ newTypes, ...cocktail, selectOptionsType: ingredientTypes, selectOptionsGlass: glassTypes, showWarning: showWarning })
        } else {
          this.props.history.push('/admin/cocktails')
        }
      });
    } else {
      this.setState({ selectOptionsType: ingredientTypes, selectOptionsGlass: glassTypes })
    }
    this._isMounted = true;
  }

  componentDidUpdate = () => {
    // console.log(this.state)
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  storeImage = (image) => {
    this.setState({ image });
  }

  publishWithConfirm = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Are you sure?"
          onConfirm={() => {
            if (this.state.cocktailId) {
              publishCocktail(this.state.cocktailId, this.state.newTypes).then(response => {
                response ? this.successMessage("published") : this.failMessage("publish");
              });
            } else {
              this.failMessage("publish")
            }
          }
          }
          onCancel={() => this.cancelMessage("Publication")}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, publish it!"
          cancelBtnText="Cancel"
          showCancel
        >
          <b>Warning</b>: This cocktail will be available for everyone!
        </SweetAlert>
      )
    });
  }

  rejectWithConfirm = () => {
    this.setState({ rejectionNote: "" })
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Are you sure?"
          onConfirm={() => {
            if (this.state.cocktailId && this.state.rejectionNote) {
              rejectCocktail(this.state.cocktailId, this.state.rejectionNote).then(response => {
                response ? this.successMessage("rejected") : this.failMessage("reject");
              });
            } else {
              this.failMessage("reject because the reason was missing. Please try again rejecting this")
            }
          }
          }
          onCancel={() => this.cancelMessage("Rejection")}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Reject it!"
          cancelBtnText="Cancel"
          showCancel
        >
          <Input placeholder="Please add a rejection note to send to the user" rows="4" style={{ marginBottom: "40px" }}
            onChange={e => this.setState({ rejectionNote: e.target.value })}
            type="textarea" />
        </SweetAlert>
      )
    });
  }

  unpublishWithConfirm = () => {
    this.setState({ unpublishNote: "" })
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Are you sure?"
          onConfirm={() => {
            if (this.state.cocktailId && this.state.unpublishNote) {
              unpublishCocktail(this.state.cocktailId, this.state.unpublishNote).then(response => {
                response ? this.successMessage("unpublished") : this.failMessage("unpublish");
              });
            } else {
              this.failMessage("remove because the reason was missing. Please try again removing this")
            }
          }
          }
          onCancel={() => this.cancelMessage("Removal")}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, remove publication!"
          cancelBtnText="Cancel"
          showCancel
        >
          <Input placeholder="Please add a reason for removing this cocktail." rows="4" style={{ marginBottom: "40px" }}
            onChange={e => this.setState({ unpublishNote: e.target.value })}
            type="textarea" />
        </SweetAlert>
      )
    });
  }

  successMessage = (action) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-200px" }}
          title="Success!"
          onConfirm={() => this.hideAlert(true)}
          onCancel={() => this.hideAlert(true)}
          confirmBtnBsStyle="info"
        >
          The cocktail has been {action}!
        </SweetAlert>
      )
    });
    this.props.history.push('/admin/cocktails/cocktail/' + this.state.cocktailId)
  }

  cancelMessage = (action) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-200px" }}
          title="Cancelled"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {action} was aborted!
        </SweetAlert>
      )
    });
  }

  failMessage = (action) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-200px" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Failed to {action} cocktail :(
        </SweetAlert>
      )
    });
  }

  hideAlert = (refresh) => {
    if (refresh) {
      this.props.history.push('/admin/cocktails/cocktail/' + this.state.cocktailId)
    } else {
      this.setState({
        alert: null
      });
    }
  }

  typeRequiredName = e => {
    let name = this.state.name;
    name["name"] = e.target.value;
    name["requiredNameState"] = this.typeRequired(name["name"])
    this.setState({ name });
  }

  typeRequired = type => {
    if (type && type.length > 0) {
      return "has-success";
    } else {
      return "has-danger";
    }
  }

  typeNumber = number => {
    let numberRex = new RegExp("^[0-9]{1,3}(,[0-9]{3})*(([\\.,]{1}[0-9]*)|())$");
    if (number && numberRex.test(number) && number > 0) {
      return "has-success";
    } else {
      return "has-danger";
    }
  }

  typeValidate = e => {
    let success = true;
    let name = this.state.name;
    name["requiredNameState"] = this.typeRequired(name["name"])
    this.setState({ name });
    if (name["requiredNameState"] !== "has-success") {
      success = false;
    }
    let ingredients = this.state.ingredients.slice();
    ingredients = ingredients.map(ingredient => {
      ingredient.typeValid = this.typeRequired(ingredient.type);
      ingredient.quantityValid = this.typeNumber(ingredient.quantity);
      if (ingredient.type === "newType") {
        ingredient.newTypeValid = this.typeRequired(ingredient.newType);
      }
      if (ingredient.typeValid === "has-danger" || ingredient.quantityValid === "has-danger" ||
        (ingredient.type === "newType" && ingredient.newTypeValid === "has-danger")) {
        success = false;
      }
      return { ...ingredient };
    })
    ingredients.sort(function (a, b) {
      return a.id - b.id || a.value.localeCompare(b.value);
    });
    this.setState({ ingredients });
    if (success) {
      addCocktail(this.state.cocktailId, this.state.name.name, this.state.description,
        this.state.glass, this.state.preparation, this.state.howToMix, this.state.ingredients, this.state.image, this.props)
        .then((response) => {
          if (response) {
            this.notify("tr", true)
            this.props.history.push('/admin/cocktails')
          } else {
            let message = "Error adding cocktail"
            let type = "danger"
            this.props.showNotification(message, type);
          }
        })
    } else {
      let message = "Please fill all required fields"
      let type = "danger"
      this.props.showNotification(message, type);
    }
  }

  addIngredient = () => {
    let ingredients = this.state.ingredients.slice();
    let nextId = Math.max.apply(Math, ingredients.map(function (o) { return o.id; }));
    nextId = nextId > 0 ? nextId + 1 : 1;
    ingredients.push({ id: nextId, type: "", quantity: "", unit: "oz", typeValid: "", quantityValid: "" })
    this.setState({ ingredients });
  }

  removeIngredient = ingredientId => {
    let ingredients = this.state.ingredients.slice();
    ingredients = ingredients.filter(ingredient => ingredient.id !== ingredientId);
    this.setState({ ingredients });
  }

  updateIngredient = (ingredientId, value, selectInput) => {
    let ingredients = this.state.ingredients.slice();
    let ingredientPointer = ingredients.findIndex((ingredient) => ingredient.id === ingredientId)
    if (selectInput === "type") {
      ingredients[ingredientPointer].type = value;
      ingredients[ingredientPointer].typeValid = this.typeRequired(value);
      if (value === "newType" && !ingredients[ingredientPointer].newType) {
        ingredients[ingredientPointer].newType = "";
        ingredients[ingredientPointer].newTypeValid = "";
        document.getElementsByClassName("newType" + ingredientId)[0].style.display = 'block';
        document.getElementById("newType" + ingredientId).focus();
      } else {
        document.getElementsByClassName("newType" + ingredientId)[0].style.display = 'none';
        ingredients[ingredientPointer].newType = "";
        ingredients[ingredientPointer].newTypeValid = "";
      }
    } else if (selectInput === "quantity") {
      ingredients[ingredientPointer].quantity = value;
      ingredients[ingredientPointer].quantityValid = this.typeNumber(value);
    } else if (selectInput === "unit") {
      ingredients[ingredientPointer].unit = value;
    } else if (selectInput === "newType") {
      ingredients[ingredientPointer].newType = value;
      ingredients[ingredientPointer].newTypeValid = this.typeRequired(value);
    }
    ingredients.sort(function (a, b) {
      return a.id - b.id || (a.value && b.value && a.value.localeCompare(b.value));
    });
    this.setState({ ingredients });
  }

  notify = (place, saved) => {
    let type;
    let message;
    if (saved) {
      type = "success";
      message = (
        <div>
          <div>
            The cocktail has been saved <b>successfully</b>.
            </div>
        </div>
      )
    } else {
      type = "danger";
      message = (
        <div>
          <div>
            <b>Failed</b> to save cocktail.
            </div>
        </div>
      )
    }
    this.props.showNotification(message, type);
  };

  addUncontrolledTooltip = ingredientId => {
    return (
      <UncontrolledTooltip placement="top" target={"remove" + ingredientId} delay={1}>
        Remove
      </UncontrolledTooltip>
    )
  }

  retractPublicationHandler = () => {
    deletePublish(this.state.id).then(deleted => {
      let type;
      let message;
      if (deleted) {
        type = "success";
        message = (
          <div>
            <div>
              Publish request has been removed <b>successfully</b>.
                </div>
          </div>
        )
        this.setState({ publish: false })
      } else {
        type = "danger";
        message = (
          <div>
            <div>
              <b>Failed</b> to remove publishing request.
                </div>
          </div>
        )
      }
      this.props.showNotification(message, type);
    })
  }

  getSubmitButtons = () => {
    if (!this.state.owner) {
      if (this.state.publish) {
        return (
          <div>
            <Button color="primary" onClick={e => this.typeValidate(e)}>Save</Button>
            <Button color="info" style={{ marginLeft: "50px" }}
              onClick={() => this.publishWithConfirm(this.state.cocktailId)}>Publish</Button>
            <Button color="danger" style={{ marginLeft: "20px" }}
              onClick={() => this.rejectWithConfirm(this.state.cocktailId)}>Reject</Button>
          </div>)
      } else {
        if (!this.state.userId && this.state.cocktailId) {
          return (
            <div>
              <Button color="primary" onClick={e => this.typeValidate(e)}>Save</Button>
              <Button color="danger" style={{ marginLeft: "20px" }}
                onClick={e => this.unpublishWithConfirm(e)}>Remove publication</Button>
            </div>)
        } else {
          return (<Button color="primary" onClick={e => this.typeValidate(e)}>Save</Button>)
        }
      }
    } else {
      if (!this.state.publish) {
        return (<Button color="primary" onClick={e => this.typeValidate(e)}>Save</Button>)
      } else {
        return (<Button color="primary" onClick={() => this.retractPublicationHandler()}>Retract publication</Button>)
      }
    }
  }

  render() {
    let Ingredients = null;
    if (this.state.ingredients !== undefined) {
      Ingredients = (
        <CardBody>
          {this.state.ingredients.map(ingredient => {
            const ingredientId = ingredient.id;
            const showNewType = ingredient.newType ? "block" : "none"
            return <div key={ingredientId}>
              <Row>
                <Col xs={12} md={6} sm={6}>
                  <Label style={{ marginTop: "10px" }}>Type</Label>
                  <Select
                    className={ingredient.typeValid + " react-select"}
                    classNamePrefix="react-select"
                    placeholder="Select type"
                    name="singleSelect"
                    value={this.state.selectOptionsType ?
                      this.state.selectOptionsType.filter(option => option.label === ingredient.type || option.value === ingredient.type) : ""}
                    options={this.state.selectOptionsType}
                    onChange={(event) => { this.updateIngredient(ingredient.id, event.value, "type") }}
                  />
                </Col>
                <Col xs={10} md={4} sm={4} className={"newType" + ingredient.id} style={{ display: showNewType }}>
                  <FormGroup className={ingredient.newTypeValid}>
                    <Label style={{ marginTop: "10px" }}>New Type Name (required)</Label>
                    <Input type="text" id={"newType" + ingredientId}
                      value={ingredient.newType ? ingredient.newType : ""}
                      onChange={(event) => this.updateIngredient(ingredient.id, event.target.value, "newType")}
                    />
                  </FormGroup>
                </Col>
                <Col xs={5} md={2} sm={2}>
                  <FormGroup className={ingredient.quantityValid}>
                    <Label style={{ marginTop: "10px" }}>Quantity</Label>
                    <Input type="number" value={ingredient.quantity}
                      onChange={event => this.updateIngredient(ingredient.id, parseFloat(event.target.value), "quantity")
                      } />
                  </FormGroup>
                </Col>
                <Col xs={5} md={2} sm={2}>
                  <FormGroup>
                    <Label style={{ marginTop: "10px" }}>Unit</Label>
                    <Select
                      className={"react-select"}
                      classNamePrefix="react-select"
                      placeholder="Select unit"
                      name="singleSelect"
                      value={this.state.selectOptionsUnit ?
                        this.state.selectOptionsUnit.filter(option => option.label === ingredient.unit || option.value === ingredient.unit) : ""}
                      options={this.state.selectOptionsUnit}
                      onChange={(event) => { this.updateIngredient(ingredient.id, event.value, "unit") }}
                    />
                  </FormGroup>
                </Col>
                <Col style={{ marginTop: "22px", paddingRight: "0px" }}>
                  <Button color="danger" className="btn-round btn-icon" id={"remove" + ingredientId}
                    onClick={e => this.removeIngredient(ingredientId)}>
                    <i className="now-ui-icons ui-1_simple-remove" />
                  </Button>
                  {this.addUncontrolledTooltip(ingredientId)}
                </Col>
              </Row>
            </div>
          })}
        </CardBody>
      )
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {this.state.alert}
          <Row>
            <Col xs={12}>
              <Form className="form-horizontal">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{this.state.cocktailId ? "" : "New "}Cocktail</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={12} sm={6} md={6}>
                        <Alert
                          color="warning"
                          isOpen={this.state.showNote}
                          toggle={this.onDismiss}
                        >
                          <span>
                            <b> Note from reviewer: </b> {this.state.reviewersNote}
                          </span>
                        </Alert>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={7}>
                        <FormGroup className={this.state.name.requiredNameState}>
                          <Label>Name (required)</Label>
                          <Input
                            type="text"
                            value={this.state.name ? this.state.name.name : ""}
                            onChange={e => this.typeRequiredName(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={7}>
                        <FormGroup>
                          <Label>Description</Label>
                          <Input placeholder="Please add a description" rows="4" cols="80"
                            onChange={event => this.setState({ "description": event.target.value })}
                            value={this.state.description} type="textarea" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={7}>
                        <FormGroup>
                          <Label>Glass</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Select glass"
                            name="singleSelect"
                            options={this.state.selectOptionsGlass}
                            value={this.state.selectOptionsGlass ?
                              this.state.selectOptionsGlass.filter(option => option.label === this.state.glass) : ""}
                            onChange={event => this.setState({ "glass": event.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={7}>
                        <FormGroup>
                          <Label>Preparation</Label>
                          <Input
                            placeholder="Please add a the instructions on how to prepare the cocktail" rows="4" cols="80"
                            onChange={event => this.setState({ "preparation": event.target.value })}
                            value={this.state.preparation} type="textarea" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={7}>
                        <FormGroup>
                          <Label>How to finish</Label>
                          <Input
                            placeholder="Please add a the instructions on how to finish the cocktail" rows="4" cols="80"
                            onChange={event => this.setState({ "howToMix": event.target.value })}
                            value={this.state.howToMix} type="textarea" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardHeader>
                    <CardTitle tag="h4">Ingredients</CardTitle>
                  </CardHeader>
                  {Ingredients}
                  <CardFooter className="text-left">
                    <Button color="warning" onClick={e => this.addIngredient()}>
                      <i className="now-ui-icons ui-1_simple-add" /> Add ingredient
                    </Button>
                  </CardFooter>
                  <CardBody>
                    <Row>
                      <Col xs={4} sm={4} md={4} lg={4}>
                        <CardTitle tag="h4">Image</CardTitle>
                        <ImageUpload defaultValue={this.state.image} storeImage={(e) => this.storeImage(e)} />
                      </Col>
                    </Row>
                    {this.state.showWarning &&
                      <Row style={{ paddingTop: "20px" }}>
                        <Col xs={12} sm={6} md={6}>
                          <div className="alert alert-danger alert-dismissible fade show" >WARNING: There is a new type also included on this cocktail. Please verify before publishing.</div>
                        </Col>
                      </Row>
                    }
                  </CardBody>
                  <CardFooter className="text-left">
                    {this.getSubmitButtons()}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default CocktailForms;
