import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { myFirebase, firebase } from "../firebase/firebase";
import PerfectScrollbar from "perfect-scrollbar";
import Footer from "components/Footer/Footer.jsx";

// Styles
import 'assets/css/firebase.css'; // This uses CSS modules.
import 'assets/css/firebaseui-styling.global.css'; // Import globally.

var ps;

const styles = () => ({
  "@global": {
    body: {
      backgroundColor: "#FF7730"
    }
  },
  paper: {
    marginTop: 50,
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 10,
    marginBottom: 0,
  },
  fontHeader: {
      paddingTop: 0,
      marginTop: 40,
      color: "#fff",
  },
  form: {
    marginTop: 1
  }
});

class Login extends Component {
    uiConfig = {
      signInFlow: 'redirect',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      // tosUrl and privacyPolicyUrl accept either url string or a callback
      // function.
      // Terms of service url/callback.
      tosUrl: 'https://mybar.io/terms-of-service',
      // Privacy policy url/callback.
      privacyPolicyUrl: function() {
        window.location.assign('https://mybar.io/privacy-policy');
      },
      callbacks: {
        signInSuccessWithAuthResult: () => false,
      }
    };

    state = {
      me: myFirebase.auth().currentUser,
      isSignedIn: undefined,
    };

    componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
    this.unregisterAuthObserver = myFirebase.auth().onAuthStateChanged((user) => {
      this.setState({isSignedIn: !!user});
    });
    }
    componentWillUnmount() {
        this.unregisterAuthObserver();
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            ps = null;
        }
    }

  render() {
    const { classes, isAuthenticated } = this.props;
    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return (
        <Container component="main" maxWidth="xs" ref="fullPages">
          <div className={classes.paper}>
            <div className={classes.avatar}>
              <img className="material-icons logo" alt="MyBar Logo" src="/images/logo.png"></img>
            </div>
            <Typography component="h1" variant="h5" className={classes.fontHeader}>
              Sign in
            </Typography>
            {this.state.isSignedIn !== undefined && !this.state.isSignedIn &&
              <div>
                <StyledFirebaseAuth className="firebaseUi" uiConfig={this.uiConfig}
                                    firebaseAuth={myFirebase.auth()}/>
              </div>
            }
            {this.state.isSignedIn &&
              <div className="signedIn">
                Hello {myFirebase.auth().currentUser.displayName}. You are now signed in.
                {this.state.itHasError !== undefined &&
                  <div>
                    But if you are still here you might want to check this error: <b>{this.state.itHasError}</b>
                  </div>
                }
                <a className="button" href="!#" onClick={() => myFirebase.auth().signOut()}>Sign-out</a>
              </div>
            }
            <Footer fluid login />
          </div>
        </Container>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated,
    me: state.me,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Login));
