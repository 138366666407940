import React from "react";

import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import App from "./App";
import configureStore from "./configureStore";

const store = configureStore();
const hist = createBrowserHistory();

function Root() {
  return (
    <Provider store={store}>
      <Router history={hist}>
        <Switch>
          <Route render={props => {
              return <App {...props} />;
          }}/>
        </Switch>
      </Router>
    </Provider>
  );
}

export default Root;
