import { myFirebase, db, firebase } from "../firebase/firebase";
import {
    getUserRole, getUserEmail, mailToAdmins, deleteImage, addNewType,
    handleFireBaseUpload, textTruncate
} from "../firebase/utils";
const defaultImage = "https://firebasestorage.googleapis.com/v0/b/bartender-72061.appspot.com/o/misc%2Fcocktails_default.jpg?alt=media&token=fbb33562-0ff7-4c9a-b6d3-ea175419a6f1";
const folder = "cocktails"

function getCocktailsHandler(cocktailId) {
    const userId = myFirebase.auth().currentUser.uid;
    let userCocktails = [];
    if (cocktailId) {
        return getUserRole().then((role) => {
            return db.collection('cocktails').doc(cocktailId).get()
                .then((data) => {
                    // Check if I'm the owner of the cocktail or role is <= 5 which is editor or Admin
                    if (data.data() && (role <= 5 || data.data().userId === userId)) {
                        let ingredients = []
                        let aux = 0;
                        if (data.data().ingredients) {
                            data.data().ingredients.forEach((ingredient) => {
                                ingredients.push({
                                    id: aux,
                                    type: ingredient.type,
                                    quantity: ingredient.quantity,
                                    unit: ingredient.unit
                                })
                                aux++;
                            });
                        }
                        return {
                            id: data.id,
                            name: { name: data.data().name },
                            description: data.data().description,
                            preparation: data.data().preparation,
                            howToMix: data.data().howToMix,
                            image: data.data().image,
                            glass: data.data().glass,
                            publish: data.data().publish,
                            reported: data.data().reported,
                            ingredients: ingredients,
                            ingredientsList: data.data().ingredientsList,
                            owner: !(role <= 5),
                            reviewersNote: data.data().reviewersNote ? data.data().reviewersNote : "",
                            showNote: data.data().showNote ? data.data().showNote : false,
                            userId: data.data().userId ? data.data().userId : undefined
                        };
                    }
                });
        });
    } else {
        return db.collection('cocktails')
            .orderBy('name', 'asc')
            .get()
            .then((data) => {
                data.forEach((doc) => {
                    if (doc.data().userId === userId) {
                        userCocktails.push({
                            id: doc.id,
                            name: doc.data().name,
                            description: textTruncate(doc.data().description),
                            preparation: doc.data().preparation,
                            howToMix: doc.data().howToMix,
                            image: doc.data().image,
                            glass: doc.data().glass,
                            publish: doc.data().publish,
                            reported: doc.data().reported,
                            ingredients: doc.data().ingredients,
                            ingredientsList: doc.data().ingredientsList,
                            published: doc.data().status === 'public' ? true : false,
                            owner: true
                        });
                    } else if (!doc.data().userId || doc.data().publish || doc.data().status === 'public') {
                        userCocktails.push({
                            id: doc.id,
                            name: doc.data().name,
                            description: textTruncate(doc.data().description),
                            preparation: doc.data().preparation,
                            howToMix: doc.data().howToMix,
                            image: doc.data().image,
                            glass: doc.data().glass,
                            publish: doc.data().publish,
                            reported: doc.data().reported,
                            ingredients: doc.data().ingredients,
                            ingredientsList: doc.data().ingredientsList,
                            published: doc.data().status === 'public' ? true : false,
                            owner: false
                        });
                    }
                });
                userCocktails = userCocktails.sort(function (a, b) { return b.owner - a.owner; });
                return { userCocktails: userCocktails };
            })
    }
}

function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

const addCocktailHandler = async (cocktailId, name, description, glass, preparation, howToMix, ingredients, image, props) => {
    const userId = myFirebase.auth().currentUser.uid;
    name = name ? name : ""
    description = description ? description : ""
    glass = glass ? glass : ""
    preparation = preparation ? preparation : ""
    howToMix = howToMix ? howToMix : ""
    let ingred = [];
    let ingredientsList = [];
    ingredients.forEach((item) => {
        if (item.type !== "newType") {
            ingredientsList.push(item.type)
            ingred.push({ type: item.type, quantity: Number(item.quantity), unit: item.unit ?? "oz" })
        } else if (item.newType) {
            // Capitalize each word
            const newType = titleCase(item.newType);
            addNewType(newType)
            ingredientsList.push(newType)
            ingred.push({ type: newType, quantity: Number(item.quantity), unit: item.unit ?? "oz" })
        }
    });
    let data = {
        name,
        description,
        glass,
        preparation,
        howToMix,
        image: image && !image.type ? image : "",
        ingredients: ingred,
        ingredientsList
    }
    let promise;
    let cocktailsRef = db.collection('cocktails');
    if (cocktailId !== undefined && cocktailId !== "" && cocktailId !== null) {
        promise = await cocktailsRef.doc(cocktailId).update(data).then(function (docRef) {
            console.log("Cocktail successfully added!");
            if (image && image.type) { handleFireBaseUpload(image, docRef ? docRef.id : cocktailId, folder, props); return true }
            else { return "true"; }
        }).catch(function (error) {
            console.log("Error adding cocktail: ", error.message);
            return false;
        });
    } else {
        data.userId = userId
        promise = cocktailsRef.add(data).then(function (docRef) {
            console.log("Cocktail successfully added!");
            if (image && image.type) { handleFireBaseUpload(image, docRef ? docRef.id : cocktailId, folder, props); return true }
            else { return "true"; }
        }).catch(function (error) {
            console.log("Error adding cocktail: ", error.message);
            return false;
        });
    }
    return promise;
}

function deleteCocktailHandler(cocktailId, image) {
    const userId = myFirebase.auth().currentUser.uid;
    return getUserRole().then((role) => {
        return db.collection('cocktails').doc(cocktailId).get()
            .then((data) => {
                // Check if I'm the owner of the cocktail or role is <= 5 which is editor or Admin
                if (role <= 5 || data.data().userId === userId) {
                    let batch = db.batch()
                    const cocktailsRef = db.collection("cocktails").doc(cocktailId)
                    batch.delete(cocktailsRef)
                    if (data.data().publish || data.data().reported) {
                        const systemRef = db.collection("system").doc("--stats--")
                        batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
                    }
                    return batch.commit().then(function () {
                        console.log("Cocktail successfully deleted!");
                        if (image && image !== defaultImage) { deleteImage(image) }
                        return true;
                    }).catch(function (error) {
                        console.log("Error removing cocktail: ", error.message);
                        return false;
                    });
                } else {
                    console.log("You don't have permission to delete this cocktail.");
                    return false;
                }
            });
    });
}

function unpublishCocktailHandler(cocktailId, note) {
    const userId = myFirebase.auth().currentUser.uid;
    return getUserRole().then((role) => {
        return db.collection('cocktails').doc(cocktailId).get()
            .then((data) => {
                // Check if I'm the owner of the cocktail or role is <= 5 which is editor or Admin
                if (role <= 5 || data.data().userId === userId) {
                    let batch = db.batch()
                    const cocktailsRef = db.collection("cocktails").doc(cocktailId)
                    batch.update(cocktailsRef, { userId: userId, status: "unpublished", reviewersNote: note, showNote: true })
                    if (data.data().publish || data.data().reported) {
                        const systemRef = db.collection("system").doc("--stats--")
                        batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
                    }
                    return batch.commit().then(function () {
                        console.log("Cocktail successfully unpublished!");
                        return true;
                    }).catch(function (error) {
                        console.log("Error unpublishing cocktail: ", error.message);
                        return false;
                    });
                } else {
                    console.log("You don't have permission to unpublish this cocktail.");
                    return false;
                }
            });
    });
}

function publishCocktailHandler(cocktailId, newTypes) {
    if (newTypes) {
        newTypes.forEach((type) => { addNewType(type.type, type.forcePublish) });
    }
    return getUserRole().then((role) => {
        return db.collection('cocktails').doc(cocktailId).get()
            .then((data) => {
                // Check if I'm the owner of the cocktail or role is <= 5 which is editor or Admin
                if (role <= 5) {
                    let batch = db.batch()
                    const cocktailsRef = db.collection("cocktails").doc(cocktailId)
                    const systemRef = db.collection("system").doc("--stats--")
                    batch.update(cocktailsRef, {
                        userId: firebase.firestore.FieldValue.delete(),
                        publish: firebase.firestore.FieldValue.delete(),
                        createdByUserId: data.data().userId,
                        status: "public"
                    })
                    batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
                    return batch.commit().then(function () {
                        console.log("Cocktail successfully published!");
                        getUserEmail(data.data().userId).then((userEmail) => {
                            if (userEmail) {
                                const template = {
                                    name: "generic",
                                    data: {
                                        subject: 'Your cocktail has been published',
                                        title: 'Yay! Your cocktail was approved!',
                                        text: 'Thanks for sharing with the community. Now, everyone will be able to enjoy your fantastic recipe: https://console.mybar.io/admin/cocktails/cocktail/' + cocktailId,
                                        html: "Thanks for sharing with the community. Now, everyone will be able to enjoy your fantastic recipe.",
                                        link: 'https://console.mybar.io/admin/cocktails/cocktail/' + cocktailId,
                                        linkDescription: 'View Cocktail >>',
                                    }
                                }
                                db.collection("mail").add({ to: userEmail, template })
                            }
                        }).catch(function (error) {
                            console.log("Error sending email confirmation: ", error.message);
                            return false;
                        });
                        return true;
                    }).catch(function (error) {
                        console.log("Error removing cocktail: ", error.message);
                        return false;
                    });
                } else {
                    console.log("You don't have permission to publish this cocktail.");
                    return false;
                }
            });
    });
}

function rejectCocktailHandler(cocktailId, note) {
    return getUserRole().then((role) => {
        return db.collection('cocktails').doc(cocktailId).get()
            .then((data) => {
                // Check if I'm the owner of the cocktail or role is <= 5 which is editor or Admin
                if (role <= 5 && data.data().userId) {
                    let batch = db.batch()
                    const cocktailsRef = db.collection("cocktails").doc(cocktailId)
                    const systemRef = db.collection("system").doc("--stats--")
                    batch.update(cocktailsRef, {
                        reviewersNote: note, showNote: true,
                        publish: firebase.firestore.FieldValue.delete()
                    })
                    batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
                    return batch.commit().then(function () {
                        console.log("Cocktail successfully rejected!");
                        getUserEmail(data.data().userId).then((userEmail) => {
                            if (userEmail) {
                                const template = {
                                    name: "generic",
                                    data: {
                                        subject: 'Your cocktail needs some updates',
                                        title: 'Hi! Your cocktail is almost there!',
                                        text: "Thanks for sharing with the community. Your cocktail isn't ready for publishing just yet. Please update the cocktail according with the following note from the reviewer and submit it again: ''"
                                            + note + "' - Cocktail link: https://console.mybar.io/admin/cocktails/cocktail/" + cocktailId,
                                        html: "Thanks for sharing with the community. Your cocktail isn't ready for publishing just yet. Please update the cocktail according with the following note from the reviewer and submit it again: '" + note + "'",
                                        link: 'https://console.mybar.io/admin/cocktails/cocktail/' + cocktailId,
                                        linkDescription: 'View Cocktail >>',
                                    }
                                }
                                db.collection("mail").add({ to: userEmail, template })
                            }
                        }).catch(function (error) {
                            console.log("Error sending email confirmation: ", error.message);
                            return false;
                        });
                        return true;
                    }).catch(function (error) {
                        console.log("Error removing cocktail: ", error.message);
                        return false;
                    });
                } else {
                    console.log("You don't have permission to publish this cocktail.");
                    return false;
                }
            });
    });
}

function getFavoritesHandler() {
    const userId = myFirebase.auth().currentUser.uid;
    let favorites = [];
    return db.collection("users/" + userId + "/favCocktails")
        .get().then(snapshot => {
            if (snapshot.empty) {
                console.log('No matching documents.');
            } else {
                snapshot.forEach(doc => {
                    favorites.push({ cocktailId: doc.id });
                })
            };
            return { favorites: favorites };
        }).catch(function (error) {
            console.error("Error returning user's role: ", error);
            return { favorites: favorites };
        });
}

function addFavoriteHandler(cocktailId) {
    const userId = myFirebase.auth().currentUser.uid;
    return db.collection("users").doc(userId + "/favCocktails/" + cocktailId)
        .set({}).then(function () {
            console.log("Favorite successfully added!");
            return true;
        }).catch(function (error) {
            console.log("Error adding Favorite: ", error.message);
            return false;
        });
}

function deleteFavoriteHandler(cocktailId) {
    const userId = myFirebase.auth().currentUser.uid;
    return db.collection("users").doc(userId + "/favCocktails/" + cocktailId).delete().then(function () {
        console.log("Favorite successfully deleted!");
        return true;
    }).catch(function (error) {
        console.log("Error removing favorite: ", error.message);
        return false;
    });
}

function addPublishHandler(cocktailId) {
    const template = {
        name: "generic",
        data: {
            subject: 'Cocktail submited for publishing',
            title: 'A new cocktail has been submited for publishing',
            text: 'A new cocktail has been submited for publishing: https://console.mybar.io/admin/cocktails/cocktail/' + cocktailId,
            html: "This is great! A new cocktail has been submited for publishing and it's ready for review.",
            link: 'https://console.mybar.io/admin/cocktails/cocktail/' + cocktailId,
            linkDescription: 'Review Cocktail >>',
        }
    }
    let batch = db.batch()
    const cocktailsRef = db.collection("cocktails").doc(cocktailId)
    const systemRef = db.collection("system").doc("--stats--")
    batch.update(cocktailsRef, { publish: true, reviewersNote: firebase.firestore.FieldValue.delete(), showNote: firebase.firestore.FieldValue.delete() })
    batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(1) })
    return batch.commit().then(function () {
        console.log("Publishing request submitted!")
        db.collection("mail").add({ to: mailToAdmins, template })
        return true;
    }).catch(function (error) {
        console.log("Error adding publishing request: ", error.message);
        return false;
    });
}

function deletePublishHandler(cocktailId) {
    let batch = db.batch()
    const cocktailsRef = db.collection("cocktails").doc(cocktailId)
    const systemRef = db.collection("system").doc("--stats--")
    batch.update(cocktailsRef, { publish: firebase.firestore.FieldValue.delete() })
    batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
    return batch.commit().then(function () {
        console.log("Publishing request retracted!");
        return true;
    }).catch(function (error) {
        console.log("Error removing publishing request: ", error.message);
        return false;
    });
}

function addReportHandler(cocktailId) {
    const userId = myFirebase.auth().currentUser.uid;
    const template = {
        name: "generic",
        data: {
            subject: 'Cocktail reported',
            title: 'A cocktail has been reported',
            text: 'A cocktail has been reported: https://console.mybar.io/admin/cocktails/cocktail/' + cocktailId,
            html: "Please be aware! A new cocktail has been reported and it's ready for review.",
            link: 'https://console.mybar.io/admin/cocktails/cocktail/' + cocktailId,
            linkDescription: 'Review Cocktail >>',
        }
    }
    const cocktailsRef = db.collection("cocktails").doc(cocktailId)
    const systemRef = db.collection("system").doc("--stats--")
    let batch = db.batch()
    batch.update(cocktailsRef, { reported: userId })
    batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(1) })
    return batch.commit().then(function () {
        console.log("Abuse report request submitted!");
        db.collection("mail").add({ to: mailToAdmins, template })
        return true;
    }).catch(function (error) {
        console.log("Error adding abuse report request: ", error.message);
        return false;
    });
}

function deleteReportHandler(cocktailId, reported) {
    const userId = myFirebase.auth().currentUser.uid;
    if (userId === reported) {
        let batch = db.batch()
        const cocktailsRef = db.collection("cocktails").doc(cocktailId)
        const systemRef = db.collection("system").doc("--stats--")
        batch.update(cocktailsRef, { reported: firebase.firestore.FieldValue.delete() })
        batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
        return batch.commit().then(function () {
            console.log("Abuse report  request retracted!");
            return true;
        }).catch(function (error) {
            console.log("Error removing abuse report  request: ", error.message);
            return false;
        });
    } else {
        return false;
    }
}

export const getCocktails = getCocktailsHandler;
export const addCocktail = addCocktailHandler;
export const deleteCocktail = deleteCocktailHandler;
export const publishCocktail = publishCocktailHandler;
export const unpublishCocktail = unpublishCocktailHandler;
export const rejectCocktail = rejectCocktailHandler;
export const getFavorites = getFavoritesHandler;
export const addFavorite = addFavoriteHandler;
export const deleteFavorite = deleteFavoriteHandler;
export const addPublish = addPublishHandler;
export const deletePublish = deletePublishHandler;
export const addReport = addReportHandler;
export const deleteReport = deleteReportHandler;
export const cocktailDefaultImage = defaultImage;
