/*eslint-disable*/
import React, { Component } from "react";
import Select from "react-select";

// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { getUsers, userDefaultImage, setUserRole } from "../../firebase/users";
import { getUserRoles } from "../../firebase/utils";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  Button
} from "reactstrap";

class UsersTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [],
      filterAll: '',
      rolesOptions: [],
      alert: null,
      show: false,
    };
  }

  componentDidMount = async () => {
      this.setState(await getUsers())
      let userRoles = await getUserRoles()
      let rolesOptions = []
      for (const role in userRoles) {
        rolesOptions.push({value: role, label: userRoles[role]})
      }
      this.setState({rolesOptions: rolesOptions})
  }

  componentDidUpdate = async () => {
      // console.log(this.state)
  }

  getUserImage = (image) => {
      return image ? image : userDefaultImage;
  }

  getAllButtons = (userId) => {
    return (
      <div className="float-right">
          <span>
              <Button className="btn-icon btn-round" color="info" id={"edit" + userId} size="sm" type="button"
                onClick={(e): void => { e.preventDefault(); this.props.history.push('/admin/profile/'+ userId); }}>
                <i className="fa fa-edit" />
              </Button>
              <UncontrolledTooltip placement="top" target={"edit" + userId} delay={1}>Edit User</UncontrolledTooltip>
          </span>
      </div>
    );
  }

  filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      const content = row[id];
      if (typeof content !== 'undefined') {
          // filter by text in the table or if it's a object, filter by key
          if (typeof content === 'object' && content !== null && content.key) {
              return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
          } else {
              return String(content).toLowerCase().includes(filter.value.toLowerCase());
          }
      }

      return true;
  };

  onFilteredChange = (filtered) => {
    if (filtered.length > 1 && this.state.filterAll.length) {
        const filterAll = '';
        this.setState({ filtered: filtered.filter((item) => item.id != 'all'), filterAll })
    } else {
        this.setState({ filtered });
    }
  }

  filterAll = (e, filterId) => {
    let newValue = e.target.value
    let filtered = this.state.filtered.filter(item => item.id !== filterId)
    filtered.push({ id: filterId, value: newValue })
    this.setState({ filtered });
  }

  updateRole = async (userId, roleId) => {
      setUserRole(userId, roleId).then(async saved => {
          let type;
          let message;
          if (saved) {
              type = "success";
              message = (
                <div>
                  <div>
                    User's role updated <b>successfully</b>.
                  </div>
                </div>
              )
              this.setState( await getUsers() )
          } else {
              type = "danger";
              message = (
                <div>
                  <div>
                    <b>Failed</b> to update user's role.
                  </div>
                </div>
              )
          }
          this.props.showNotification(message, type);
      })
  }

  getRoleDropdown = (userId, roleId) => {
      return (
        <Select style={{overflow: "inherit"}}
          className="react-select"
          classNamePrefix="react-select"
          placeholder="Select Role"
          name="singleSelect"
          value={this.state.rolesOptions ?
          this.state.rolesOptions.filter(option => option.label === roleId) : ""}
          options={this.state.rolesOptions}
          onChange={e => this.updateRole(userId, e.value)}
        />
      )
  }

  render() {
    return (
      <>
      <PanelHeader size="sm" />
      <div className="content elegantList">
        {this.state.alert}
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                    <CardTitle tag="h4" className="float-left">Users List</CardTitle>
                </CardHeader>
                <CardBody style={{clear: 'both'}}>
                    <Row style={{paddingBottom: "20px"}} className="adminFilters">
                       <Col sm="4">
                         <FormGroup style={{paddingTop:"10px"}}>
                           <Input type="text" placeholder="Search by ID"
                           onChange={(e) => this.filterAll(e, "id")} />
                         </FormGroup>
                       </Col>
                    </Row>
                  <ReactTable
                    data={this.state.users}
                    filtered={this.state.filtered}
                    onFilteredChange={(e) => this.onFilteredChange(e)}
                    filterable
                    columns={[
                      {
                        Header: "Image",
                        sortable: false,
                        filterable: false,
                        maxWidth: 100,
                        minWidth: 100,
                        Cell: (row) => {
                            return <div><img src={this.getUserImage(row.original.image)} width="200"/></div>
                        }
                      },
                      {
                        Header: "User",
                        accessor: "name",
                        minWidth: 300,
                        Cell: (row) => {
                            return <div className="align-vertical-top"><p><b>{row.original.name}</b></p><p>{row.original.aboutMe}</p></div>
                        }
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                        minWidth: 300,
                        Cell: (row) => {
                            return <div className="align-vertical-top"><p>{row.original.email}</p></div>
                        }
                      },
                      {
                        Header: "Last Login",
                        accessor: "lastLogin",
                        minWidth: 250,
                        Cell: (row) => {
                            return <div className="align-vertical-top"><p>{row.original.lastLogin ? (new Date(row.original.lastLogin * 1000).toLocaleDateString("en-US",
                            { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: "numeric", minute: "numeric"})) : ""}</p></div>
                        }
                      },
                      {
                        Header: "Role",
                        accessor: "role",
                        minWidth: 150,
                        Cell: (row) => { return this.getRoleDropdown(row.original.id, row.original.role) }
                      },
                      {
                        accessor: "id",
                        show: false,
                      },
                      {
                        Header: "Actions",
                        sortable: false,
                        filterable: false,
                        maxWidth: 150,
                        minWidth: 150,
                        Cell: (row) => {
                            return this.getAllButtons(row.original.id)
                        }
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom
                    className="-striped -highlight"
                    defaultFilterMethod={this.filterCaseInsensitive}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default UsersTables;
