import { myFirebase, db, firebase } from "../firebase/firebase";
import {
    getUserRole, getUserEmail, mailToAdmins, deleteImage, addNewType,
    handleFireBaseUpload, textTruncate, titleCase
} from "../firebase/utils";
const defaultImage = "https://firebasestorage.googleapis.com/v0/b/bartender-72061.appspot.com/o/misc%2Fbottle_default.jpg?alt=media&token=6600a8f0-db92-4747-b7f8-da0b0025366f";
const folder = "bottles"

function getBottlesHandler(bottleId) {
    const userId = myFirebase.auth().currentUser.uid;
    let promise;
    let userBottles = [];
    if (bottleId) {
        return getUserRole().then((role) => {
            return db.collection('bottles').doc(bottleId).get()
                .then((data) => {
                    // Check if I'm the owner of the bottle or role is < 10 which is editor or Admin
                    if (role <= 10 || data.data().userId === userId) {
                        let sizesList = []
                        let aux = 0;
                        if (data.data().sizes) {
                            data.data().sizes.forEach((size) => {
                                sizesList.push({
                                    id: aux,
                                    size: size.size,
                                    unit: size.unit ?? "oz",
                                    upc: size.upc
                                })
                                aux++;
                            });
                        }
                        return {
                            id: data.id,
                            name: { name: data.data().name },
                            description: data.data().description,
                            type: { type: data.data().type },
                            image: data.data().image,
                            abv: data.data().abv ? data.data().abv : 0,
                            sizes: sizesList,
                            publish: data.data().publish,
                            reported: data.data().reported,
                            owner: !(role <= 5),
                            reviewersNote: data.data().reviewersNote ? data.data().reviewersNote : "",
                            showNote: data.data().showNote ? data.data().showNote : false,
                            userId: data.data().userId ? data.data().userId : undefined
                        };
                    }
                });
        });
    } else {
        promise = db.collection('bottles')
            .orderBy('name', 'asc')
            .get()
            .then((data) => {
                data.forEach((doc) => {
                    if (doc.data().userId === userId) {
                        userBottles.push({
                            id: doc.id,
                            name: doc.data().name,
                            description: textTruncate(doc.data().description),
                            type: doc.data().type,
                            image: doc.data().image,
                            abv: doc.data().abv,
                            sizes: doc.data().sizes,
                            publish: doc.data().publish,
                            published: doc.data().status === 'public' ? true : false,
                            reported: doc.data().reported,
                            owner: true
                        });
                    } else if (!doc.data().userId || doc.data().publish || doc.data().status === 'public') {
                        userBottles.push({
                            id: doc.id,
                            name: doc.data().name,
                            description: textTruncate(doc.data().description),
                            type: doc.data().type,
                            image: doc.data().image,
                            abv: doc.data().abv,
                            sizes: doc.data().sizes,
                            publish: doc.data().publish,
                            published: doc.data().status === 'public' ? true : false,
                            reported: doc.data().reported,
                            owner: false
                        });
                    }
                });
                userBottles = userBottles.sort(function (a, b) { return b.owner - a.owner; });
                return { userBottles: userBottles };
            })
    }
    return promise;
}

const addBottleHandler = async (bottleId, name, type, description, abv, sizes, image, props) => {
    const userId = myFirebase.auth().currentUser.uid;
    let typeValue = type ? type.type : ""
    if (typeValue === "newType") {
        // Capitalize each word
        typeValue = titleCase(type.newType);
        addNewType(typeValue)
    }
    name = name ? name : ""
    description = description ? description : ""
    abv = Number(abv) ? Number(abv) : 0
    let sizesList = [];
    if (sizes) {
        sizes.forEach((item) => {
            sizesList.push({ size: item.size, unit: item.unit ?? "oz", upc: item.upc })
        });
    }
    let data = {
        name,
        type: typeValue,
        description,
        abv,
        image: image && !image.type ? image : "",
        sizes: sizesList
    }
    let promise;
    let bottlesRef = db.collection('bottles');
    if (bottleId !== undefined && bottleId !== "" && bottleId !== null) {
        promise = await bottlesRef.doc(bottleId).update(data).then(function (docRef) {
            console.log("Bottle successfully added!");
            if (image && image.type) { handleFireBaseUpload(image, docRef ? docRef.id : bottleId, folder, props); return true }
            else { return "true"; }
        }).catch(function (error) {
            console.log("Error adding bottle: ", error.message);
            return false;
        });
    } else {
        data.userId = userId
        promise = bottlesRef.add(data).then(function (docRef) {
            console.log("Bottle successfully added!");
            if (image && image.type) { handleFireBaseUpload(image, docRef ? docRef.id : bottleId, folder, props); return true }
            else { return "true"; }
        }).catch(function (error) {
            console.log("Error adding bottle: ", error.message);
            return false;
        });
    }
    return promise;
}

function deleteBottleHandler(bottleId, image) {
    const userId = myFirebase.auth().currentUser.uid;
    return getUserRole().then((role) => {
        return db.collection('bottles').doc(bottleId).get()
            .then((data) => {
                // Check if I'm the owner of the bottle or role is <= 5 which is editor or Admin
                if (role <= 5 || data.data().userId === userId) {
                    let batch = db.batch()
                    const bottlesRef = db.collection("bottles").doc(bottleId)
                    batch.delete(bottlesRef)
                    if (data.data().publish || data.data().reported) {
                        const systemRef = db.collection("system").doc("--stats--")
                        batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
                    }
                    return batch.commit().then(function () {
                        console.log("Bottle successfully deleted!");
                        if (image && image !== defaultImage) { deleteImage(image) }
                        return true;
                    }).catch(function (error) {
                        console.log("Error removing bottle: ", error.message);
                        return false;
                    });
                } else {
                    console.log("You don't have permission to delete this bottle.");
                    return false;
                }
            });
    });
}

function unpublishBottleHandler(bottleId, note) {
    const userId = myFirebase.auth().currentUser.uid;
    return getUserRole().then((role) => {
        return db.collection('bottles').doc(bottleId).get()
            .then((data) => {
                // Check if I'm the owner of the bottle or role is <= 5 which is editor or Admin
                if (role <= 5 || data.data().userId === userId) {
                    let batch = db.batch()
                    const bottlesRef = db.collection("bottles").doc(bottleId)
                    batch.update(bottlesRef, { userId: userId, status: "unpublished", reviewersNote: note, showNote: true })
                    if (data.data().publish || data.data().reported) {
                        const systemRef = db.collection("system").doc("--stats--")
                        batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
                    }
                    return batch.commit().then(function () {
                        console.log("Bottle successfully unpublished!");
                        return true;
                    }).catch(function (error) {
                        console.log("Error unpublishing bottle: ", error.message);
                        return false;
                    });
                } else {
                    console.log("You don't have permission to unpublish this bottle.");
                    return false;
                }
            });
    });
}

function publishBottleHandler(bottleId, newTypes) {
    if (newTypes) {
        newTypes.forEach((type) => { addNewType(type.type, type.forcePublish) });
    }
    return getUserRole().then((role) => {
        return db.collection('bottles').doc(bottleId).get()
            .then((data) => {
                // Check if I'm the owner of the bottle or role is <= 5 which is editor or Admin
                if (role <= 5 && data.data().userId) {
                    let batch = db.batch()
                    const bottlesRef = db.collection("bottles").doc(bottleId)
                    const systemRef = db.collection("system").doc("--stats--")
                    batch.update(bottlesRef, {
                        userId: firebase.firestore.FieldValue.delete(),
                        publish: firebase.firestore.FieldValue.delete(), createdByUserId: data.data().userId,
                        status: "public"
                    })
                    batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
                    return batch.commit().then(function () {
                        console.log("Bottle successfully published!");
                        getUserEmail(data.data().userId).then((userEmail) => {
                            if (userEmail) {
                                const template = {
                                    name: "generic",
                                    data: {
                                        subject: 'Your bottle has been published',
                                        title: 'Yay! Your bottle was approved!',
                                        text: 'Thanks for sharing with the community. Now, everyone will be able to enjoy your fantastic recipe: https://console.mybar.io/admin/bottles/bottle/' + bottleId,
                                        html: "Thanks for sharing with the community. Now, everyone will be able to enjoy your fantastic recipe.",
                                        link: 'https://console.mybar.io/admin/bottles/bottle/' + bottleId,
                                        linkDescription: 'View Bottle >>',
                                    }
                                }
                                db.collection("mail").add({ to: userEmail, template })
                            }
                        }).catch(function (error) {
                            console.log("Error sending email confirmation: ", error.message);
                            return false;
                        });
                        return true;
                    }).catch(function (error) {
                        console.log("Error removing bottle: ", error.message);
                        return false;
                    });
                } else {
                    console.log("You don't have permission to publish this bottle.");
                    return false;
                }
            });
    });
}

function rejectBottleHandler(bottleId, note) {
    return getUserRole().then((role) => {
        return db.collection('bottles').doc(bottleId).get()
            .then((data) => {
                // Check if I'm the owner of the bottle or role is <= 5 which is editor or Admin
                if (role <= 5 && data.data().userId) {
                    let batch = db.batch()
                    const bottlesRef = db.collection("bottles").doc(bottleId)
                    const systemRef = db.collection("system").doc("--stats--")
                    batch.update(bottlesRef, {
                        reviewersNote: note, showNote: true,
                        publish: firebase.firestore.FieldValue.delete()
                    })
                    batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
                    return batch.commit().then(function () {
                        console.log("Bottle successfully rejected!");
                        getUserEmail(data.data().userId).then((userEmail) => {
                            if (userEmail) {
                                const template = {
                                    name: "generic",
                                    data: {
                                        subject: 'Your bottle needs some updates',
                                        title: 'Hi! Your bottle is almost there!',
                                        text: "Thanks for sharing with the community. Your bottle isn't ready for publishing just yet. Please update the bottle according with the following note from the reviewer and submit it again: ''"
                                            + note + "' - Bottle link: https://console.mybar.io/admin/bottles/bottle/" + bottleId,
                                        html: "Thanks for sharing with the community. Your bottle isn't ready for publishing just yet. Please update the bottle according with the following note from the reviewer and submit it again: '" + note + "'",
                                        link: 'https://console.mybar.io/admin/bottles/bottle/' + bottleId,
                                        linkDescription: 'View Bottle >>',
                                    }
                                }
                                db.collection("mail").add({ to: userEmail, template })
                            }
                        }).catch(function (error) {
                            console.log("Error sending email confirmation: ", error.message);
                            return false;
                        });
                        return true;
                    }).catch(function (error) {
                        console.log("Error removing bottle: ", error.message);
                        return false;
                    });
                } else {
                    console.log("You don't have permission to publish this bottle.");
                    return false;
                }
            });
    });
}

function getFavoritesHandler() {
    const userId = myFirebase.auth().currentUser.uid;
    let favorites = [];
    return db.collection("users/" + userId + "/favBottles")
        .get().then(snapshot => {
            if (snapshot.empty) {
                console.log('No matching documents.');
            } else {
                snapshot.forEach(doc => {
                    favorites.push({ bottleId: doc.id });
                })
            };
            return { favorites: favorites };
        }).catch(function (error) {
            console.error("Error returning user's role: ", error);
            return { favorites: favorites };
        });
}

function addFavoriteHandler(bottleId) {
    const userId = myFirebase.auth().currentUser.uid;
    return db.collection("users").doc(userId + "/favBottles/" + bottleId)
        .set({}).then(function () {
            console.log("Favorite successfully added!");
            return true;
        }).catch(function (error) {
            console.log("Error adding Favorite: ", error.message);
            return false;
        });
}

function deleteFavoriteHandler(bottleId) {
    const userId = myFirebase.auth().currentUser.uid;
    return db.collection("users").doc(userId + "/favBottles/" + bottleId).delete().then(function () {
        console.log("Favorite successfully deleted!");
        return true;
    }).catch(function (error) {
        console.log("Error removing favorite: ", error.message);
        return false;
    });
}

function addPublishHandler(bottleId) {
    const template = {
        name: "generic",
        data: {
            subject: 'Bottle submited for publishing',
            title: 'A new bottle has been submited for publishing',
            text: 'A new bottle has been submited for publishing: https://console.mybar.io/admin/bottles/bottle/' + bottleId,
            html: "This is great! A new bottle has been submited for publishing and it's ready for review.",
            link: 'https://console.mybar.io/admin/bottles/bottle/' + bottleId,
            linkDescription: 'Review Bottle >>',
        }
    }
    let batch = db.batch()
    const bottlesRef = db.collection("bottles").doc(bottleId)
    const systemRef = db.collection("system").doc("--stats--")
    batch.update(bottlesRef, { publish: true, reviewersNote: firebase.firestore.FieldValue.delete(), showNote: firebase.firestore.FieldValue.delete() })
    batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(1) })
    return batch.commit().then(function () {
        console.log("Publishing request submitted!")
        db.collection("mail").add({ to: mailToAdmins, template })
        return true;
    }).catch(function (error) {
        console.log("Error adding publishing request: ", error.message);
        return false;
    });
}

function deletePublishHandler(bottleId) {
    let batch = db.batch()
    const bottlesRef = db.collection("bottles").doc(bottleId)
    const systemRef = db.collection("system").doc("--stats--")
    batch.update(bottlesRef, { publish: firebase.firestore.FieldValue.delete() })
    batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
    return batch.commit().then(function () {
        console.log("Publishing request retracted!");
        return true;
    }).catch(function (error) {
        console.log("Error removing publishing request: ", error.message);
        return false;
    });
}

function addReportHandler(bottleId) {
    const userId = myFirebase.auth().currentUser.uid;
    const template = {
        name: "generic",
        data: {
            subject: 'Bottle reported',
            title: 'A bottle has been reported',
            text: 'A bottle has been reported: https://console.mybar.io/admin/bottles/bottle/' + bottleId,
            html: "Please be aware! A new bottle has been reported and it's ready for review.",
            link: 'https://console.mybar.io/admin/bottles/bottle/' + bottleId,
            linkDescription: 'Review Bottle >>',
        }
    }
    const bottlesRef = db.collection("bottles").doc(bottleId)
    const systemRef = db.collection("system").doc("--stats--")
    let batch = db.batch()
    batch.update(bottlesRef, { reported: userId })
    batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(1) })
    return batch.commit().then(function () {
        console.log("Abuse report request submitted!");
        db.collection("mail").add({ to: mailToAdmins, template })
        return true;
    }).catch(function (error) {
        console.log("Error adding abuse report request: ", error.message);
        return false;
    });
}

function deleteReportHandler(bottleId, reported) {
    const userId = myFirebase.auth().currentUser.uid;
    if (userId === reported) {
        let batch = db.batch()
        const bottlesRef = db.collection("bottles").doc(bottleId)
        const systemRef = db.collection("system").doc("--stats--")
        batch.update(bottlesRef, { reported: firebase.firestore.FieldValue.delete() })
        batch.update(systemRef, { needsAttention: firebase.firestore.FieldValue.increment(-1) })
        return batch.commit().then(function () {
            console.log("Abuse report  request retracted!");
            return true;
        }).catch(function (error) {
            console.log("Error removing abuse report  request: ", error.message);
            return false;
        });
    } else {
        return false;
    }
}

export const getBottles = getBottlesHandler;
export const addBottle = addBottleHandler;
export const deleteBottle = deleteBottleHandler;
export const publishBottle = publishBottleHandler;
export const unpublishBottle = unpublishBottleHandler;
export const rejectBottle = rejectBottleHandler;
export const getFavorites = getFavoritesHandler;
export const addFavorite = addFavoriteHandler;
export const deleteFavorite = deleteFavoriteHandler;
export const addPublish = addPublishHandler;
export const deletePublish = deletePublishHandler;
export const addReport = addReportHandler;
export const deleteReport = deleteReportHandler;
export const bottleDefaultImage = defaultImage;
