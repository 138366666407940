import { db } from "../firebase/firebase";
import { getUserRole } from "../firebase/utils";

// views
import Dashboard from "../views/Dashboard/Dashboard.jsx";
import Buttons from "../views/Components/Buttons.jsx";
import GridSystem from "../views/Components/GridSystem.jsx";
import Panels from "../views/Components/Panels.jsx";
import SweetAlert from "../views/Components/SweetAlertPage.jsx";
import Notifications from "../views/Components/Notifications.jsx";
import Icons from "../views/Components/Icons.jsx";
import Typography from "../views/Components/Typography.jsx";
import RegularForms from "../views/Forms/RegularForms.jsx";
import ExtendedForms from "../views/Forms/ExtendedForms.jsx";
import ValidationForms from "../views/Forms/ValidationForms.jsx";
import Wizard from "../views/Forms/Wizard/Wizard.jsx";
import RegularTables from "../views/Tables/RegularTables.jsx";
import ExtendedTables from "../views/Tables/ExtendedTables.jsx";
import GoogleMaps from "../views/Maps/GoogleMaps.jsx";
import FullScreenMap from "../views/Maps/FullScreenMap.jsx";
import VectorMap from "../views/Maps/VectorMap.jsx";
import Charts from "../views/Charts/Charts.jsx";
import Calendar from "../views/Calendar/Calendar.jsx";
import Widgets from "../views/Widgets/Widgets.jsx";
import UserPage from "../views/Pages/UserPage.jsx";
import TimelinePage from "../views/Pages/TimelinePage.jsx";
import RTL from "../views/Pages/RTL.jsx";
import PricingPage from "../views/Pages/PricingPage.jsx";
import LoginPage from "../views/Pages/LoginPage.jsx";
import RegisterPage from "../views/Pages/RegisterPage.jsx";
import LockScreenPage from "../views/Pages/LockScreenPage.jsx";
import ReactTable from "../views/Tables/ReactTable.jsx";
import CocktailsTables from "../views/Tables/CocktailsTables.jsx";
import CocktailForms from "../views/Forms/CocktailForms.jsx";
import BottlesTables from "../views/Tables/BottlesTables.jsx";
import BottleForms from "../views/Forms/BottleForms.jsx";
import UsersTables from "../views/Tables/UsersTables.jsx";
import MenuTables from "../views/Tables/MenuTables.jsx";

const fetchMenus = async () => {
    const role = await getUserRole();
    return fetchAllMenus(role)
}

function fetchAllMenus(userRole) {
    let obj = {
        Dashboard: Dashboard,
        Buttons: Buttons,
        GridSystem: GridSystem,
        Panels: Panels,
        SweetAlert: SweetAlert,
        Notifications: Notifications,
        Icons: Icons,
        Typography: Typography,
        RegularForms: RegularForms,
        ExtendedForms: ExtendedForms,
        ValidationForms: ValidationForms,
        Wizard: Wizard,
        RegularTables: RegularTables,
        ExtendedTables: ExtendedTables,
        ReactTable: ReactTable,
        GoogleMaps: GoogleMaps,
        FullScreenMap: FullScreenMap,
        VectorMap: VectorMap,
        Charts: Charts,
        Calendar: Calendar,
        Widgets: Widgets,
        UserPage: UserPage,
        TimelinePage: TimelinePage,
        RTL: RTL,
        PricingPage: PricingPage,
        LoginPage: LoginPage,
        RegisterPage: RegisterPage,
        LockScreenPage: LockScreenPage,
        CocktailsTables: CocktailsTables,
        CocktailForms: CocktailForms,
        BottlesTables: BottlesTables,
        BottleForms: BottleForms,
        UsersTables: UsersTables,
        MenuTables: MenuTables
    }

  let role = userRole;
  return db.collection("menus")
    .orderBy("order")
    .get()
    .then(querySnapshot => {
        var data = [];
        const userRole = role;
        querySnapshot.forEach(function (documentSnapshot) {
          // Check if user can see this menu
          const role = documentSnapshot.data().role;
          if (!role || role >= userRole) {
              let menuItem = {};
              const layout = documentSnapshot.data().layout;
              if (layout) {
                  menuItem = {
                    order: documentSnapshot.data().order,
                    path: documentSnapshot.data().path,
                    name: documentSnapshot.data().name,
                    icon: documentSnapshot.data().icon,
                    component: obj[documentSnapshot.data().component],
                    layout
                  }
              } else {
                  menuItem = {
                      collapse: true,
                      path: documentSnapshot.data().path,
                      name: documentSnapshot.data().name,
                      state: documentSnapshot.data().state,
                      icon: documentSnapshot.data().icon,
                      views: []
                  }
                  let views = [];
                  const dbViews = documentSnapshot.data().views;
                  Object.keys(dbViews).forEach((item) => {
                      const i = dbViews[item];
                      views.push({
                            name: item,
                            path: i.path,
                            mini: i.mini,
                            layout: i.layout,
                            component: obj[i.component]
                      })
                  });
                  menuItem.views = views;
              }
              data.push(menuItem);
          }
        });
        data.push({ layout: "redirect" });
        return { routes: data }
    });
}

export const fetchUserMenu = fetchMenus;
