import React from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { getIngredientTypes } from "../../firebase/utils";
import { addBottle, getBottles, deletePublish, publishBottle, rejectBottle, unpublishBottle } from "../../firebase/bottles";

// reactstrap components
import {
  Alert,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

class BottleForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unpublishNote: "",
      rejectionNote: "",
      newTypes: [],
      selectOptionsUnit: [{ label: "oz", value: "oz" }, { label: "ml", value: "ml" }, { label: "L", value: "L" }],
      owner: false,
      showWarning: false,
      showNote: false,
      abv: 0,
      selectOptionsType: [],
      bottleId: props.location.pathname.split("/")[4],
      name: { name: "", requiredNameState: "" },
      type: { type: "", requiredTypeState: "" },
      alert: null,
      show: false,
    };
  }

  onDismiss = () => { this.setState({ showNote: false }) };

  componentDidMount = async () => {
    let ingredientTypes = await getIngredientTypes();
    if (this.state.bottleId) {
      getBottles(this.state.bottleId).then((bottle) => {
        if (bottle) {
          let showWarning = false
          let newType = bottle.type
          if (newType && ingredientTypes.findIndex((ingred) => newType.type === ingred.value && !ingred.owner) <= -1) {
            showWarning = true
            newType = { type: "newType", newType: newType.type }
            this.setState({ newTypes: [{ type: newType.newType, forcePublish: true }] })
          }
          bottle.type = newType
          if (newType.newType) {
            document.getElementsByClassName("newType")[0].style.display = 'block';
          }
          this.setState({ ...bottle, selectOptionsType: ingredientTypes, showWarning: showWarning })
        } else {
          this.props.history.push('/admin/bottles')
        }
      });
    } else {
      this.setState({ selectOptionsType: ingredientTypes });
    }
    this._isMounted = true;
  }

  componentDidUpdate = () => {
    // console.log(this.state)
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  storeImage = (image) => {
    this.setState({ image });
  }

  publishWithConfirm = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Are you sure?"
          onConfirm={() => {
            if (this.state.bottleId) {
              publishBottle(this.state.bottleId, this.state.newTypes).then(response => {
                response ? this.successMessage("published") : this.failMessage("publish");
              });
            } else {
              this.failMessage("publish")
            }
          }
          }
          onCancel={() => this.cancelMessage("Publication")}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, publish it!"
          cancelBtnText="Cancel"
          showCancel
        >
          <b>Warning</b>: This bottle will be available for everyone!
        </SweetAlert>
      )
    });
  }

  rejectWithConfirm = () => {
    this.setState({ rejectionNote: "" })
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Are you sure?"
          onConfirm={() => {
            if (this.state.bottleId && this.state.rejectionNote) {
              rejectBottle(this.state.bottleId, this.state.rejectionNote).then(response => {
                response ? this.successMessage("rejected") : this.failMessage("reject");
              });
            } else {
              this.failMessage("reject because the reason was missing. Please try again rejecting this")
            }
          }
          }
          onCancel={() => this.cancelMessage("Rejection")}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Reject it!"
          cancelBtnText="Cancel"
          showCancel
        >
          <Input placeholder="Please add a rejection note to send to the user" rows="4" style={{ marginBottom: "40px" }}
            onChange={e => this.setState({ rejectionNote: e.target.value })}
            type="textarea" />
        </SweetAlert>
      )
    });
  }

  unpublishWithConfirm = () => {
    this.setState({ unpublishNote: "" })
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Are you sure?"
          onConfirm={() => {
            if (this.state.bottleId && this.state.unpublishNote) {
              unpublishBottle(this.state.bottleId, this.state.unpublishNote).then(response => {
                response ? this.successMessage("unpublished") : this.failMessage("unpublish");
              });
            } else {
              this.failMessage("remove because the reason was missing. Please try again removing this")
            }
          }
          }
          onCancel={() => this.cancelMessage("Removal")}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, remove publication!"
          cancelBtnText="Cancel"
          showCancel
        >
          <Input placeholder="Please add a reason for removing this bottle." rows="4" style={{ marginBottom: "40px" }}
            onChange={e => this.setState({ unpublishNote: e.target.value })}
            type="textarea" />
        </SweetAlert>
      )
    });
  }

  successMessage = (action) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-200px" }}
          title="Success!"
          onConfirm={() => this.hideAlert(true)}
          onCancel={() => this.hideAlert(true)}
          confirmBtnBsStyle="info"
        >
          The bottle has been {action}!
        </SweetAlert>
      )
    });
  }

  cancelMessage = (action) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-200px" }}
          title="Cancelled"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {action} was aborted!
        </SweetAlert>
      )
    });
  }

  failMessage = (action) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-200px" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Failed to {action} bottle :(
        </SweetAlert>
      )
    });
  }

  hideAlert = (refresh) => {
    if (refresh) {
      this.props.history.push('/admin/bottles/bottle/' + this.state.bottleId)
    } else {
      this.setState({
        alert: null
      });
    }
  }

  typeRequiredName = e => {
    let name = this.state.name;
    name["name"] = e.target.value;
    name["requiredNameState"] = this.typeRequired(name["name"])
    this.setState({ name });
  }

  typeRequiredType = e => {
    let type = this.state.type ? this.state.type : { type: "", requiredTypeState: "" };
    type["type"] = e.value;
    type["requiredTypeState"] = this.typeRequired(type["type"])
    if (type.type === "newType" && !type.newType) {
      type.newType = "";
      type.newTypeValid = "";
      document.getElementsByClassName("newType")[0].style.display = 'block';
      document.getElementById("newType").focus();
    } else {
      document.getElementsByClassName("newType")[0].style.display = 'none';
      type.newType = "";
      type.newTypeValid = "";
    }
    this.setState({ type });
  }

  typeRequired = type => {
    if (type && type.length > 0) {
      return "has-success";
    } else {
      return "has-danger";
    }
  }

  typeNumber = number => {
    let numberRex = new RegExp("^[0-9]{1,3}(,[0-9]{3})*(([\\.,]{1}[0-9]*)|())$");
    if (number && numberRex.test(number) && number > 0) {
      return "has-success";
    } else {
      return "has-danger";
    }
  }

  typeValidate = e => {
    let success = true;
    let name = this.state.name;
    name["requiredNameState"] = this.typeRequired(name["name"])
    this.setState({ name });
    if (name["requiredNameState"] !== "has-success") {
      success = false;
    }
    let type = this.state.type;
    type.requiredTypeState = this.typeRequired(type.type)
    this.setState({ type });
    if (type.type === "newType") {
      type.newTypeValid = this.typeRequired(type.newType);
    }
    if (type["requiredTypeState"] !== "has-success" || (type["newTypeValid"] && type["newTypeValid"] !== "has-success")) {
      success = false;
    }
    if (this.state.sizes) {
      let sizes = this.state.sizes.slice();
      sizes = sizes.map(size => {
        size.sizeValid = this.typeNumber(size.size);
        if (size.sizeValid === "has-danger") {
          success = false;
        }
        return { ...size };
      })
      sizes.sort(function (a, b) {
        return a.id - b.id || a.value.localeCompare(b.value);
      });
      this.setState({ sizes });
    }
    if (success) {
      addBottle(this.state.bottleId, this.state.name.name, this.state.type,
        this.state.description, this.state.abv, this.state.sizes, this.state.image, this.props)
        .then((response) => {
          if (response) {
            this.notify("tr", true)
            this.props.history.push('/admin/bottles')
          } else {
            let message = "Error adding bottle"
            let type = "danger"
            this.props.showNotification(message, type);
          }
        })
    } else {
      let message = "Please fill all required fields"
      let type = "danger"
      this.props.showNotification(message, type);
    }
  }

  addSize = () => {
    let sizes = this.state.sizes ? this.state.sizes.slice() : [];
    let nextId = 0;
    if (sizes !== []) {
      nextId = Math.max.apply(Math, sizes.map(function (o) { return o.id; }));
      nextId = nextId > 0 ? nextId + 1 : 1;
    }
    sizes.push({ id: nextId, size: "", unit: "oz", upc: "", sizeValid: "", upcValid: "" })
    this.setState({ sizes });
  }

  removeSize = sizeId => {
    let sizes = this.state.sizes.slice();
    sizes = sizes.filter(size => size.id !== sizeId);
    this.setState({ sizes });
  }

  notify = (place, saved) => {
    let type;
    let message;
    if (saved) {
      type = "success";
      message = (
        <div>
          <div>
            The bottle has been saved <b>successfully</b>.
            </div>
        </div>
      )
    } else {
      type = "danger";
      message = (
        <div>
          <div>
            <b>Failed</b> to save bottle.
            </div>
        </div>
      )
    }
    this.props.showNotification(message, type);
  };

  addUncontrolledTooltip = sizeId => {
    return (
      <UncontrolledTooltip placement="top" target={"remove" + sizeId} delay={1}>
        Remove
      </UncontrolledTooltip>
    )
  }

  updateSize = (sizeId, value, selectInput) => {
    let sizes = this.state.sizes.slice();
    let sizePointer = sizes.findIndex((size) => size.id === sizeId)
    if (selectInput === "size") {
      sizes[sizePointer].size = value;
      sizes[sizePointer].sizeValid = this.typeNumber(value);
    } else if (selectInput === "unit") {
      sizes[sizePointer].unit = value;
    } else if (selectInput === "upc") {
      sizes[sizePointer].upc = value;
    }
    sizes.sort(function (a, b) {
      return a.id - b.id || (a.value && b.value && a.value.localeCompare(b.value));
    });
    this.setState({ sizes });
  }

  handleChange = (event, eventType) => {
    if (eventType === "newType") {
      let type = this.state.type;
      type.newType = event.target.value;
      type.newTypeValid = this.typeRequired(type.newType);
      this.setState({ type });
    } else if (eventType === "abv") {
      this.setState({ abv: event.target.value });
    }
  }

  retractPublicationHandler = () => {
    deletePublish(this.state.id).then(deleted => {
      let type;
      let message;
      if (deleted) {
        type = "success";
        message = (
          <div>
            <div>
              Publish request has been removed <b>successfully</b>.
                </div>
          </div>
        )
        this.setState({ publish: false })
      } else {
        type = "danger";
        message = (
          <div>
            <div>
              <b>Failed</b> to remove publishing request.
                </div>
          </div>
        )
      }
      this.props.showNotification(message, type);
    })
  }

  getSubmitButtons = () => {
    if (!this.state.owner) {
      if (this.state.publish) {
        return (
          <div>
            <Button color="primary" onClick={e => this.typeValidate(e)}>Save</Button>
            <Button color="info" style={{ marginLeft: "50px" }}
              onClick={() => this.publishWithConfirm(this.state.bottleId)}>Publish</Button>
            <Button color="danger" style={{ marginLeft: "20px" }}
              onClick={() => this.rejectWithConfirm(this.state.bottleId)}>Reject</Button>
          </div>)
      } else {
        if (!this.state.userId && this.state.bottleId) {
          return (
            <div>
              <Button color="primary" onClick={e => this.typeValidate(e)}>Save</Button>
              <Button color="danger" style={{ marginLeft: "20px" }}
                onClick={e => this.unpublishWithConfirm(e)}>Remove publication</Button>
            </div>)
        } else {
          return (<Button color="primary" onClick={e => this.typeValidate(e)}>Save</Button>)
        }
      }
    } else {
      if (!this.state.publish) {
        return (<Button color="primary" onClick={e => this.typeValidate(e)}>Save</Button>)
      } else {
        return (<Button color="primary" onClick={() => this.retractPublicationHandler()}>Retract publication</Button>)
      }
    }
  }

  render() {
    let Sizes = null;
    if (this.state.sizes !== undefined) {
      Sizes = (
        <CardBody>
          {this.state.sizes.map(size => {
            let sizeId = size.id;
            return <div key={sizeId}>
              <Row>
                <Col lg={2} xs={4} md={4} sm={4}>
                  <FormGroup className={size.sizeValid}>
                    <Label style={{ marginTop: "10px" }}>Size</Label>
                    <Input type="number" value={size.size}
                      onChange={event => this.updateSize(size.id, parseFloat(event.target.value), "size")
                      } />
                  </FormGroup>
                </Col>
                <Col lg={2} xs={4} md={4} sm={4}>
                  <FormGroup>
                    <Label style={{ marginTop: "10px" }}>Unit</Label>
                    <Select
                      className={"react-select"}
                      classNamePrefix="react-select"
                      placeholder="Select unit"
                      name="singleSelect"
                      value={this.state.selectOptionsUnit ?
                        this.state.selectOptionsUnit.filter(option => option.label === size.unit || option.value === size.unit) : ""}
                      options={this.state.selectOptionsUnit}
                      onChange={(event) => { this.updateSize(size.id, event.value, "unit") }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} xs={6} md={6} sm={6}>
                  <FormGroup className={size.upcValid}>
                    <Label style={{ marginTop: "10px" }}>UPC</Label>
                    <Input type="text" value={size.upc}
                      onChange={event => this.updateSize(size.id, event.target.value, "upc")
                      } />
                  </FormGroup>
                </Col>
                <Col style={{ marginTop: "22px", paddingRight: "0px" }}>
                  <Button color="danger" className="btn-round btn-icon" id={"remove" + sizeId}
                    onClick={e => this.removeSize(sizeId)}>
                    <i className="now-ui-icons ui-1_simple-remove" />
                  </Button>
                  {this.addUncontrolledTooltip(sizeId)}
                </Col>
              </Row>
            </div>
          })}
        </CardBody>
      )
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {this.state.alert}
          <Row>
            <Col xs={12}>
              <Form className="form-horizontal">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">{this.state.bottleId ? "" : "New "}Bottle</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={12} sm={6} md={6}>
                        <Alert
                          color="warning"
                          isOpen={this.state.showNote}
                          toggle={this.onDismiss}
                        >
                          <span>
                            <b> Note from reviewer: </b> {this.state.reviewersNote}
                          </span>
                        </Alert>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={7}>
                        <FormGroup className={this.state.name.requiredNameState}>
                          <Label>Brand (required)</Label>
                          <Input
                            type="text"
                            value={this.state.name ? this.state.name.name : ""}
                            onChange={e => this.typeRequiredName(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={7}>
                        <FormGroup>
                          <Label>Type (required)</Label>
                          <Select
                            className={this.state.type.requiredTypeState + " react-select"}
                            classNamePrefix="react-select"
                            placeholder="Select type"
                            name="singleSelect"
                            value={this.state.selectOptionsType ?
                              this.state.selectOptionsType.filter(option => option.label === this.state.type.type || option.value === this.state.type.type) : ""}
                            options={this.state.selectOptionsType}
                            onChange={e => this.typeRequiredType(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={7} className={"newType"} style={{ display: "none" }}>
                        <FormGroup className={this.state.type.newTypeValid}>
                          <Label style={{ marginTop: "10px" }}>New Type Name (required)</Label>
                          <Input type="text" id={"newType"}
                            value={this.state.type.newType ? this.state.type.newType : ""}
                            onChange={(event) => this.handleChange(event, "newType")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={7}>
                        <FormGroup>
                          <Label>Description</Label>
                          <Input placeholder="Please add a description" rows="4" cols="80"
                            onChange={event => this.setState({ "description": event.target.value })}
                            value={this.state.description} type="textarea" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={7}>
                        <FormGroup>
                          <Label>Alcohol by Volume (Proof divided by 2)</Label>
                          <Input type="number" value={this.state.abv}
                            onChange={event => this.handleChange(event, "abv")} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardHeader>
                    <CardTitle tag="h4">Sizes</CardTitle>
                  </CardHeader>
                  {Sizes}
                  <CardFooter className="text-left">
                    <Button color="warning" onClick={e => this.addSize()}>
                      <i className="now-ui-icons ui-1_simple-add" /> Add new size
                    </Button>
                  </CardFooter>
                  <CardBody>
                    <Row>
                      <Col xs={12} sm={6} md={6}>
                        <CardTitle tag="h4">Image</CardTitle>
                        <ImageUpload defaultValue={this.state.image} storeImage={(e) => this.storeImage(e)} />
                      </Col>
                    </Row>
                    {this.state.showWarning &&
                      <Row style={{ paddingTop: "20px" }}>
                        <Col xs={12} sm={6} md={6}>
                          <div className="alert alert-danger alert-dismissible fade show" >WARNING: There is a new type also included on this cocktail. Please verify before publishing.</div>
                        </Col>
                      </Row>
                    }
                  </CardBody>
                  <CardFooter className="text-left">
                    {this.getSubmitButtons()}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default BottleForms;
