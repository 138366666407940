/*eslint-disable*/
import React, { Component } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { getUserRole } from "../../firebase/utils";
import { getCocktails, deleteCocktail, getFavorites, addFavorite, deleteFavorite, addPublish, deletePublish, addReport, deleteReport, cocktailDefaultImage } from "../../firebase/cocktails";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

// reactstrap components
import {
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  FormGroup,
  UncontrolledTooltip,
  Button
} from "reactstrap";

class CocktailsTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [],
      filterAll: '',
      alert: null,
      show: false,
    };
  }

  componentDidMount() {
    getUserRole().then(role => { this.setState({ userRole: role }) });
    getCocktails().then((cocktails) => { this.setState(cocktails) });
    getFavorites().then((favorites) => { this.setState(favorites) });
  }

  componentDidUpdate() {
    // console.log(this.state)
  }

  getCocktailImage = (image) => {
    return image ? image : cocktailDefaultImage;
  }

  warningWithConfirmAndCancelMessage = (cocktailId, image) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title="Are you sure?"
          onConfirm={() => {
            if (cocktailId) {
              deleteCocktail(cocktailId, image).then(response => {
                response ? this.successDelete() : this.failDelete();
              });
            } else {
              this.failDelete()
            }
          }
          }
          onCancel={() => this.cancelDetele()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        >
          You will not be able to recover this cocktail!
        </SweetAlert>
      )
    });
  }

  successDelete = () => {
    getCocktails().then((cocktails) => { this.setState(cocktails) });
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-200px" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Your cocktail has been deleted.
        </SweetAlert>
      )
    });
  }

  cancelDetele = () => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-200px" }}
          title="Cancelled"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Your cocktail is safe :)
        </SweetAlert>
      )
    });
  }

  failDelete = () => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-200px" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Failed to delete cocktail :(
        </SweetAlert>
      )
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  }

  addPublishHandler = (itemId) => {
    addPublish(itemId).then(saved => {
      let type;
      let message;
      if (saved) {
        type = "success";
        message = (
          <div>
            <div>
              Publishing request has been submitted <b>successfully</b>.
                  </div>
          </div>
        )
        getCocktails().then((cocktails) => { this.setState(cocktails) });
      } else {
        type = "danger";
        message = (
          <div>
            <div>
              <b>Failed</b> to submit publishing request.
                  </div>
          </div>
        )
      }
      this.props.showNotification(message, type);
    })
  }

  deletePublishHandler = (itemId) => {
    deletePublish(itemId).then(deleted => {
      let type;
      let message;
      if (deleted) {
        type = "success";
        message = (
          <div>
            <div>
              Publish request has been removed <b>successfully</b>.
                  </div>
          </div>
        )
        getCocktails().then((cocktails) => { this.setState(cocktails) });
      } else {
        type = "danger";
        message = (
          <div>
            <div>
              <b>Failed</b> to remove publishing request.
                  </div>
          </div>
        )
      }
      this.props.showNotification(message, type);
    })
  }

  addFavoriteHandler = (itemId) => {
    addFavorite(itemId).then(saved => {
      let type;
      let message;
      if (saved) {
        type = "success";
        message = (
          <div>
            <div>
              Favorite has been added <b>successfully</b>.
                  </div>
          </div>
        )
        getCocktails().then((cocktails) => { this.setState(cocktails) });
        getFavorites().then((favorites) => { this.setState(favorites) });
      } else {
        type = "danger";
        message = (
          <div>
            <div>
              <b>Failed</b> to add favorite.
                  </div>
          </div>
        )
      }
      this.props.showNotification(message, type);
    })
  }

  deleteFavoriteHandler = (itemId) => {
    deleteFavorite(itemId).then(deleted => {
      let type;
      let message;
      if (deleted) {
        type = "success";
        message = (
          <div>
            <div>
              Favorite has been removed <b>successfully</b>.
                  </div>
          </div>
        )
        getCocktails().then((cocktails) => { this.setState(cocktails) });
        getFavorites().then((favorites) => { this.setState(favorites) });
      } else {
        type = "danger";
        message = (
          <div>
            <div>
              <b>Failed</b> to remove favorite.
                  </div>
          </div>
        )
      }
      this.props.showNotification(message, type);
    })
  }

  addReportHandler = (itemId) => {
    addReport(itemId).then(saved => {
      let type;
      let message;
      if (saved) {
        type = "success";
        message = (
          <div>
            <div>
              Report has been submitted <b>successfully</b>.
                </div>
          </div>
        )
        getCocktails().then((cocktails) => { this.setState(cocktails) });
        getFavorites().then((favorites) => { this.setState(favorites) });
      } else {
        type = "danger";
        message = (
          <div>
            <div>
              <b>Failed</b> to submit report.
                </div>
          </div>
        )
      }
      this.props.showNotification(message, type);
    })
  }

  deleteReportHandler = (itemId, reportedId) => {
    deleteReport(itemId, reportedId).then(deleted => {
      let type;
      let message;
      if (deleted) {
        type = "success";
        message = (
          <div>
            <div>
              Report has been retracted <b>successfully</b>.
                </div>
          </div>
        )
        getCocktails().then((cocktails) => { this.setState(cocktails) });
        getFavorites().then((favorites) => { this.setState(favorites) });
      } else {
        type = "danger";
        message = (
          <div>
            <div>
              <b>Failed</b> to retract report.
                </div>
          </div>
        )
      }
      this.props.showNotification(message, type);
    })
  }

  getFavoriteButton = (cocktailId) => {
    let favorites = this.state.favorites;
    if (favorites) {
      const isFavoritePointer = favorites.findIndex((cocktail) => cocktail.cocktailId === cocktailId)
      const isFavorite = favorites[isFavoritePointer];
      if (isFavorite) {
        return (
          <span>
            <Button className="btn-icon btn-round" color="warning" id={"fav" + cocktailId} size="sm" type="button"
              onClick={(e) => { e.preventDefault(); this.deleteFavoriteHandler(cocktailId); }} >
              <i className="fa fa-heart" />
              {/* <i className="now-ui-icons ui-2_favourite-28" /> */}
            </Button>
            <UncontrolledTooltip placement="top" target={"fav" + cocktailId} delay={1}>Remove from favorites</UncontrolledTooltip>
          </span>
        )
      } else {
        return (
          <span>
            <Button className="btn-icon btn-round" color="warning" id={"fav" + cocktailId} size="sm" type="button"
              onClick={(e) => { e.preventDefault(); this.addFavoriteHandler(cocktailId); }} >
              <i className="now-ui-icons ui-2_favourite-28" />
            </Button>
            <UncontrolledTooltip placement="top" target={"fav" + cocktailId} delay={1}>Add to favorites</UncontrolledTooltip>
          </span>
        )
      }
    }
  }

  getPublishButton = (cocktailId, publish) => {
    if (!publish) {
      return (
        <span>
          <Button className="btn-icon btn-round" color="success" id={"publish" + cocktailId} size="sm" type="button"
            onClick={(e) => { e.preventDefault(); this.addPublishHandler(cocktailId); }} >
            <i className="fa fa-share" />
          </Button>
          <UncontrolledTooltip placement="top" target={"publish" + cocktailId} delay={1}>
            Submit for publishing
                  </UncontrolledTooltip>
        </span>
      )
    } else {
      return (
        <span>
          <Button className="btn-icon btn-round" color="disabled" id={"publish" + cocktailId} size="sm" type="button"
            onClick={(e) => { e.preventDefault(); this.deletePublishHandler(cocktailId); }} >
            <i className="fa fa-share" />
          </Button>
          <UncontrolledTooltip placement="top" target={"publish" + cocktailId} delay={1}>
            Retract publishing request
                  </UncontrolledTooltip>
        </span>
      )
    }
  }

  getReportButton = (cocktailId, reported) => {
    if (!reported) {
      return (
        <span>
          <Button className="btn-icon btn-round" color="danger" id={"report" + cocktailId} size="sm" type="button"
            onClick={(e) => { e.preventDefault(); this.addReportHandler(cocktailId); }} >
            <i className="fa fa-bullhorn" />
          </Button>
          <UncontrolledTooltip placement="top" target={"report" + cocktailId} delay={1}>
            Report abuse
                  </UncontrolledTooltip>
        </span>
      )
    } else {
      return (
        <span>
          <Button className="btn-icon btn-round" color="disabled" id={"report" + cocktailId} size="sm" type="button"
            onClick={(e) => { e.preventDefault(); this.deleteReportHandler(cocktailId, reported); }} >
            <i className="fa fa-bullhorn" />
          </Button>
          <UncontrolledTooltip placement="top" target={"report" + cocktailId} delay={1}>
            Retract abuse report
                  </UncontrolledTooltip>
        </span>
      )
    }
  }

  getAllButtons = (itemId, image, publish, published, reported, owner) => {
    if (owner || this.state.userRole <= 5) {
      return (
        <div className="float-right">
          {this.getFavoriteButton(itemId)}
          {!published && owner && this.getPublishButton(itemId, publish)}
          <Button className="btn-icon btn-round" color="info" id={"edit" + itemId} size="sm" type="button"
            onClick={(e) => { e.preventDefault(); this.props.history.push('/admin/cocktails/cocktail/' + itemId); }}>
            <i className="fa fa-edit" />
          </Button>
          <UncontrolledTooltip placement="top" target={"edit" + itemId} delay={1}>
            Edit
                </UncontrolledTooltip>
          <Button className="btn-icon btn-round" color="danger" id={"delete" + itemId} size="sm" type="button"
            onClick={() => this.warningWithConfirmAndCancelMessage(itemId, image)}>
            <i className="fa fa-times" />
          </Button>
          <UncontrolledTooltip placement="top" target={"delete" + itemId} delay={1}>
            Delete
                </UncontrolledTooltip>
        </div>
      );
    } else {
      return (
        <div className="float-right">
          {this.getFavoriteButton(itemId)}
          {this.getReportButton(itemId, reported)}
        </div>
      );
    }
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== 'undefined') {
      // filter by text in the table or if it's a object, filter by key
      if (typeof content === 'object' && content !== null && content.key) {
        return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
      } else {
        return String(content).toLowerCase().includes(filter.value.toLowerCase());
      }
    }
    return true;
  };

  onFilteredChange = (filtered) => {
    if (filtered.length > 1 && this.state.filterAll.length) {
      const filterAll = '';
      this.setState({ filtered: filtered.filter((item) => item.id != 'all'), filterAll })
    } else {
      this.setState({ filtered });
    }
  }

  filterAll = (e, filterId) => {
    let newValue = "";
    if (filterId === "publish" || filterId === "reported") {
      newValue = e.target.checked ? "true" : "";
    } else {
      newValue = e.target.value
    }
    let filtered = this.state.filtered.filter(item => item.id !== filterId)
    filtered.push({ id: filterId, value: newValue })
    this.setState({ filtered });
  }

  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content elegantList">
          {this.state.alert}
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="float-left">Cocktails List</CardTitle>
                  <Button className="btn-round btn btn-primary float-right" color="warning" size="big" type="button"
                    onClick={(e) => { e.preventDefault(); this.props.history.push('/admin/cocktails/cocktail/'); }}>
                    <i className="now-ui-icons ui-1_simple-add" /> Add new cocktail
                        </Button>
                </CardHeader>
                <CardBody style={{ clear: 'both' }}>
                  {this.state.userRole <= 10 &&
                    <Row style={{ paddingBottom: "20px" }} className="adminFilters">
                      <Col sm="4">
                        <FormGroup style={{ paddingTop: "10px" }}>
                          <Input type="text" placeholder="Cocktail ID"
                            onChange={(e) => this.filterAll(e, "id")} />
                        </FormGroup>
                      </Col>
                      <FormGroup check className="mt-3">
                        <Label check>
                          <Input type="checkbox"
                            onChange={(e) => this.filterAll(e, "publish")} />
                          <span className="form-check-sign" />
                              Publishing Requested
                            </Label>
                      </FormGroup>
                      <FormGroup check className="mt-3" style={{ marginLeft: "10px" }}>
                        <Label check>
                          <Input type="checkbox"
                            onChange={(e) => this.filterAll(e, "reported")} />
                          <span className="form-check-sign" />
                              Reported
                            </Label>
                      </FormGroup>
                    </Row>
                  }
                  <ReactTable
                    data={this.state.userCocktails}
                    filtered={this.state.filtered}
                    onFilteredChange={(e) => this.onFilteredChange(e)}
                    filterable
                    columns={[
                      {
                        Header: "Image",
                        sortable: false,
                        filterable: false,
                        maxWidth: 200,
                        minWidth: 200,
                        Cell: (row) => {
                          return <div className="height-120"><img src={this.getCocktailImage(row.original.image)} /></div>
                        }
                      },
                      {
                        Header: "Cocktail",
                        accessor: "name",
                        minWidth: 240,
                        Cell: (row) => {
                          return <div className="align-vertical-top"><p><b>{row.original.name}</b></p><p>{row.original.description}</p></div>
                        }
                      },
                      {
                        Header: "Ingredients",
                        accessor: "ingredientsList",
                        minWidth: 200,
                        Cell: (row) => {
                          return ingredientsList(row.original.ingredients, row.original.id)
                        }

                      },
                      {
                        Header: "Glass",
                        accessor: "glass",
                        maxWidth: 150,
                        minWidth: 150,
                      },
                      {
                        id: "publish",
                        accessor: cocktail => cocktail.publish ? cocktail.publish.toString() : "false",
                        show: false,
                      },
                      {
                        id: "reported",
                        accessor: cocktail => cocktail.reported ? cocktail.reported.toString() : "false",
                        show: false,
                      },
                      {
                        accessor: "id",
                        show: false,
                      },
                      {
                        Header: "Actions",
                        sortable: false,
                        filterable: false,
                        maxWidth: 150,
                        minWidth: 150,
                        Cell: (row) => {
                          return this.getAllButtons(row.original.id, row.original.image, row.original.publish, row.original.published, row.original.reported, row.original.owner)
                        }
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom
                    className="-striped -highlight"
                    defaultFilterMethod={this.filterCaseInsensitive}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function ingredientsList(ingredients, itemId) {
  if (ingredients) {
    const listItems = ingredients.map((ingredient, i) =>
      <li key={i.toString()}>{ingredient.quantity} {ingredient.unit ?? "oz"}. - {ingredient.type}</li>
    );
    return (
      <ul>{listItems}</ul>
    );
  } else {
    return ""
  }
}

export default CocktailsTables;
